import React, { useMemo } from "react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { Col, Nav, NavItem, NavLink as NavLinkStrap } from "reactstrap";
import classnames from "classnames";
import Fuse from "fuse.js";
import { selectChickasawWords, selectEnglishWords } from "../../store/selectors";
import { WordTab } from "./wordTab";
import { CHICKASAW_COLS, ENGLISH_COLS } from "./list/fields";

import "./styles.scss";

export const BASE_URL = "/words";

export const EDITOR_CHICKASAW_URL = `${BASE_URL}/chickasaw`;
export const EDITOR_CHICKASAW_ADD_NEW_URL = `${EDITOR_CHICKASAW_URL}/add`;
export const EDITOR_CHICKASAW_URL_ID = `${EDITOR_CHICKASAW_URL}/:id`;

export const EDITOR_ENGLISH_URL = `${BASE_URL}/english`;
export const EDITOR_ENGLISH_ADD_NEW_URL = `${EDITOR_ENGLISH_URL}/add`;
export const EDITOR_ENGLISH_URL_ID = `${EDITOR_ENGLISH_URL}/:id`;

export const Editor: React.FC = () => {
    const chickasawWords = useSelector(selectChickasawWords);
    const englishWords = useSelector(selectEnglishWords);
    const location = useLocation();
    const pathParts = useMemo(() => location.pathname.split("/"), [location]);
    const lastPart = useMemo(() => pathParts[pathParts.length - 1], [pathParts]);
    const isOnEditorPanel = useMemo(() => !isNaN(+lastPart) && !isNaN(parseFloat(lastPart)), [lastPart]);

    const [dataLoaded, setDataLoaded] = useState(!!chickasawWords || !!englishWords);

    useEffect(() => {
        if (chickasawWords && chickasawWords.length) {
            setDataLoaded(true);
        }
    }, [chickasawWords]);

    useEffect(() => {
        if (englishWords && englishWords.length) {
            setDataLoaded(true);
        }
    }, [englishWords]);

    if (!dataLoaded) {
        return (
            <div className="debug_notice">Loading...</div>
        );
    } else {
        return (<React.Fragment>
            {/* <div>
                Data Loaded!
                <span> Chickasaw Words: {chickasawWords?.length}</span>
                <span> English Words: {englishWords?.length}</span>
            </div> */}
            <Nav tabs={true} className="word-tab-btns">
                <NavItem>
                    <NavLink
                        to={EDITOR_CHICKASAW_URL}
                        className={classnames("nav-link", {
                            "editor-active": isOnEditorPanel,
                        })}
                    >Chickasaw Words</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        to={EDITOR_ENGLISH_URL}
                        className={classnames("nav-link", {
                            "editor-active": isOnEditorPanel,
                        })}
                    >English Words</NavLink>
                </NavItem>
            </Nav>
            <Switch>
                <Route path={EDITOR_ENGLISH_URL} render={(() => (
                    <WordTab
                        wordData={englishWords}
                        fieldData={ENGLISH_COLS}
                    />
                ))} />
                <Route path={EDITOR_CHICKASAW_URL} render={(() => (
                    <WordTab
                        wordData={chickasawWords}
                        fieldData={CHICKASAW_COLS}
                    />
                ))} />
            </Switch>
        </React.Fragment>);
    }
}
