import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CreatableSelect, { makeCreatableSelect } from "react-select/creatable";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import { EditorFieldBaseProps } from ".";
import { selectChickasawWords, selectEnglishWords } from "../../../../store/selectors";
import { GetColumnFriendlyName } from "../../../helpers";

interface PartOfSpeechDisplayProps {
    value: string;
    style?: React.CSSProperties;
}

export const POS_TYPE = "partofspeech";

export const PartOfSpeechDisplay: React.FC<PartOfSpeechDisplayProps> = ({ value, style }) => {
    return (
        <span
            className={classNames("td", POS_TYPE)}
            style={style}
        >{value}</span>
    );
};

interface PartOfSpeechEditorProps extends EditorFieldBaseProps {
    label: string;
    value: string | null;
    handleChange: (val: { currentTarget: { value: any, checked?: boolean, name: string } }) => void;
}

export const PartOfSpeechEditor: React.FC<PartOfSpeechEditorProps> = ({ label, value, handleChange, isEditable }) => {
    const parsedLabel = useMemo(() => GetColumnFriendlyName(label), [label]);
    const cwWords = useSelector(selectChickasawWords);
    const enWords = useSelector(selectEnglishWords);
    const handleCb = useCallback((val: any, actionMeta: any) => {
        let thisVal = "";
        if (actionMeta.action != "clear") {
            thisVal = val.value;
        }
        let changeObj = {
            currentTarget: {
                name: label,
                value: thisVal,
            }
        };
        handleChange(changeObj);
    }, [label]);

    const posOptions = useMemo(() => {
        let results: any[] = [];
        const dictKeys: Record<string, string> = {};

        if (enWords != null && enWords.length > 0) {
            for (let word of enWords) {
                dictKeys[word.part_of_speech] = "";
            }
        }

        if (cwWords != null && cwWords.length > 0) {
            for (let word of cwWords) {
                dictKeys[word.part_of_speech] = "";
            }
        }

        for (let key of Object.keys(dictKeys)) {
            if (key.length > 0) {
                let selectOpt = {
                    label: key,
                    value: key,
                };
                results.push(selectOpt);
            }
        }

        results.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);

        return results;
    }, [cwWords, enWords]);
    
    const parsedValue = useMemo(() => {
        var obj = {
            label: value,
            value,
        }
        return obj;
    }, [value]);

    return (
        <FormGroup tag={Row}>
            <Col sm={2}>
                <Label className="col-form-label" for={label}>{parsedLabel}</Label>
            </Col>
            <Col sm={10}>
                <CreatableSelect
                    options={posOptions}
                    isMulti={false}
                    isClearable={true}
                    onChange={handleCb}
                    // onInputChange={handleCb}
                    value={parsedValue}
                    // createOptionPosition="first"
                // value={selectedOptions}
                // onChange={handleTranslatedSelectChange}
                />
                {/* <Input
                    type="text"
                    name={label}
                    id={label}
                    placeholder={initialValue ?? ""}
                    value={value ?? ""}
                    onChange={handleChange}
                    disabled={!isEditable}
                /> */}
            </Col>
        </FormGroup>
    );
};
