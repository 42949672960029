import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { FormGroup, Label, Input, Col, Row } from "reactstrap";
import { EditorFieldBaseProps } from ".";
import { MenuList } from "./../../menuList";
import { MultiValueLabel } from "../../multiValueLabel";
import { ChickasawWords, EnglishWords } from "../../../../store/data";
import { selectChickasawWords, selectEnglishWords } from "../../../../store/selectors";
import { GetColumnFriendlyName, getWordLabel, SelectValueOption } from "../../../helpers";

export const TRANSLATED_TYPE = "translated";

interface TranslatedDisplayProps {
    value: (ChickasawWords | EnglishWords)[];
    style?: React.CSSProperties;
}

export const TranslatedDisplay: React.FC<TranslatedDisplayProps> = ({ value, style }) => {
    let result = value?.length ?? 0;
    return (
        <span
            className={classNames("td", TRANSLATED_TYPE)}
            style={style}
        >{result}</span>
    );
};

interface TranslatedEditorProps extends EditorFieldBaseProps {
    value: ChickasawWords[] | EnglishWords[] | null;
    handleChange: (fieldName: string, value: any, sendToServer: boolean) => void;
    record: ChickasawWords | EnglishWords;
    disabled: null | boolean;
}

export const TranslatedEditor: React.FC<TranslatedEditorProps> = ({ label, value, handleChange, isEditable, isModified, record, disabled }) => {
    const parsedLabel = useMemo(() => GetColumnFriendlyName(label), [label]);

    const cwWords = useSelector(selectChickasawWords);
    const enWords = useSelector(selectEnglishWords);
    
    const targetList = useMemo(() => ("cn_word_pronunciation" in record ? enWords : cwWords) as (ChickasawWords | EnglishWords)[], [record, cwWords, enWords]);

    const availableOptions = useMemo(() => {
        if (targetList == null || targetList.length == 0) {
            return null;
        }

        var result: SelectValueOption[] = (targetList as (ChickasawWords | EnglishWords)[]).map((word) => ({
            value: word.id,
            label: getWordLabel(word).replace(/\*/g, ""),
        }));

        return result;
    }, [targetList]);

    const selectedOptions = useMemo(() => {
        if (value == null || value.length == 0) {
            return [];
        }
        return (value as (ChickasawWords | EnglishWords)[])?.map((val) => val != null && availableOptions?.find((opt) => opt.value == val.id));
    }, [availableOptions, value]);

    const handleTranslatedSelectChange = useCallback((val: SelectValueOption[] | null, actionMeta: any) => {
        let result = "";
        if (val && val.length) {
            result = val.map((v) => v.value).join(",");
        }

        if (result.length > 0 && targetList != null && targetList.length > 0) {
            const targetIds = result.split(",");
            const thisValue = targetIds.map((id) => targetList.find((rec) => rec.id == +id));
            handleChange("fKs", result, true);
            handleChange("Translated", thisValue, false);
        }
    }, [availableOptions, targetList, handleChange]);

    return (
        <FormGroup className="translated_words" tag={Row}>
            <Col sm={2}>
                <Label className="col-form-label" for={label}>{parsedLabel}</Label>
            </Col>
            <Col sm={10}>
                <Select
                    options={availableOptions!}
                    components={{ MenuList, MultiValueLabel }}
                    isMulti={true}
                    isDisabled={disabled || !isEditable}
                    value={selectedOptions}
                    onChange={handleTranslatedSelectChange}
                />
            </Col>
        </FormGroup>
    );
};
