import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import Select from "react-select";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import { EditorFieldBaseProps } from ".";
import { ChickasawWords, EnglishWords } from "../../../../store/data";
import { GetColumnFriendlyName, SelectValueOption } from "../../../helpers";

interface MergeEditorProps extends EditorFieldBaseProps {
    value: string | null;
    duplicates: (ChickasawWords | EnglishWords)[] | null;
    handleChange: (fieldName: string, value: any, sendToServer: boolean) => void;
}

export const MergeEditor: React.FC<MergeEditorProps> = ({ label, value, duplicates, handleChange, isEditable }) => {
    const parsedLabel = useMemo(() => GetColumnFriendlyName(label), [label]);
    const [initialValue] = useState(value);

    const thisEntryValue = {
        value: initialValue,
        label: initialValue,
    };

    const selectOptions = useMemo(() => {
        let results = [];
        results.push({
            value: "",
            label: "",
        });

        if (initialValue != null && initialValue.length > 0) {
            results.push(thisEntryValue);
        }

        if (duplicates != null && duplicates.length > 0) {
            for (var dupe of duplicates) {
                const val = (dupe as any)[label];
                if (val == null || val.length == 0) {
                    continue;
                }
                if (results.some(m => m.label == val)) {
                    continue;
                }
                results.push({
                    value: val,
                    label: val,
                });
            }
        }

        return results;
    }, [initialValue, thisEntryValue, value, duplicates]);

    const handleMergeChange = useCallback((val: SelectValueOption | null, actionMeta: any) => {
        handleChange(label, val?.value, true);
    }, [handleChange, label]);

    if (label.toLowerCase() == "word") {
        return (
            <FormGroup tag={Row}>
                <Col sm={2}>
                    <Label className="col-form-label" for={label}>{parsedLabel}</Label>
                </Col>
                <Col sm={10}>
                    <Input
                        type="text"
                        value={value ?? ""}
                        disabled={true}
                    />
                </Col>
            </FormGroup>
        )
    }

    return (
        <FormGroup tag={Row}>
            <Col sm={2}>
                <Label className="col-form-label" for={label}>{parsedLabel}</Label>
            </Col>
            <Col sm={10}>
                <Select
                    options={selectOptions}
                    defaultValue={thisEntryValue}
                    onChange={handleMergeChange}
                    disabled={!isEditable}
                />
            </Col>
        </FormGroup>
    );
};
