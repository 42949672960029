import classNames from "classnames";
import RichTextEditor, { EditorValue } from 'react-rte';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import marked from "marked";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import { EditorFieldBaseProps } from ".";
import { GetColumnFriendlyName } from "../../../helpers";

import "draft-js/dist/Draft.css";

interface MarkdownDisplayProps {
    value: string;
    style?: React.CSSProperties;
}

export const MARKDOWN_TYPE = "markdown";

export const MarkdownDisplay: React.FC<MarkdownDisplayProps> = ({ value, style }) => {
    return (
        <span
            className={classNames("td", MARKDOWN_TYPE)}
            style={style}
            dangerouslySetInnerHTML={{ __html: marked.parseInline(value) }}
        ></span>
    );
};

const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        {
            label: 'Italic',
            style: 'ITALIC',
        },
    ],
    BLOCK_TYPE_BUTTONS: [],
    BLOCK_TYPE_DROPDOWN: [],
};

const getParsedMD = (val: EditorValue) => val.toString('markdown').split("_").join("*").trim();

interface MarkdownEditorProps extends EditorFieldBaseProps {
    label: string;
    value: string | null;
    //handleChange: (val: React.ChangeEvent<HTMLInputElement>) => void;
    handleChange: (val: { currentTarget: { value: any, checked?: boolean, name: string }}) => void;
}

export const MarkdownEditor: React.FC<MarkdownEditorProps> = ({ label, value, handleChange, isEditable }) => {
    const parsedLabel = useMemo(() => GetColumnFriendlyName(label), [label]);
    const [initialValue, setInitialValue] = useState("");

    const [editorState, setEditorState] = React.useState(() =>
        RichTextEditor.createValueFromString(value ?? "", 'markdown')
    );

    useEffect(() => {
        if (!value || initialValue.length > 0) {
            return;
        }
        setInitialValue(value);
        setEditorState(RichTextEditor.createValueFromString(value ?? "", 'markdown'));
        //console.log("MarkdownEditor::useEffect value created for " + value);
    }, [value, setEditorState]);

    // const rteRef = useRef<RichTextEditor>(null);

    const handleRTEChange = useCallback((val: EditorValue) => {
        var md = getParsedMD(val);

        setEditorState(v => {
            var orig = getParsedMD(v);
            if (orig != md) {
                // console.log(md);
                // bubble change event when value actually changes
                const changeObj = {
                    currentTarget: {
                        value: md,
                        name: label,
                    }
                };
                console.log("handleRTEChange", changeObj);
                handleChange(changeObj);
            }

            return val;
        });
    }, [setEditorState, handleChange]);

    return (
        <FormGroup tag={Row}>
            <Col sm={2}>
                <Label className="col-form-label" for={label}>{parsedLabel}</Label>
            </Col>
            <Col>
                <RichTextEditor
                    // ref={rteRef}
                    autoFocus={true}
                    toolbarConfig={toolbarConfig as any}
                    value={editorState}
                    onChange={handleRTEChange}
                    placeholder={initialValue}
                />
            </Col>
            {/* <Col sm={5}>
                <Input
                    type="text"
                    name={label}
                    id={label}
                    placeholder={initialValue ?? ""}
                    value={value ?? ""}
                    onChange={handleChange}
                    disabled={!isEditable}
                />
            </Col>
            <Col sm={5}>
                <div
                    className="form-control non-editable"
                    dangerouslySetInnerHTML={{ __html: marked.parseInline(value ?? "") }}
                ></div>
            </Col> */}
        </FormGroup>
    );
};


/*
Login/Logout,


Word RTE for Italic
Accent picker ?
Live edit updates bottom preview
search dropdown update to use italics/parsing
word of the day -- add year, calendar picker w/year (allow multiple?)



missing part of speech warning (when matching word has one, or multiple identical values)


previews - slightly different for eng vs chk words

add preview of both


card:
* word (p_o_s) (note)


*/