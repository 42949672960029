import * as Data from "./data";
import { AnyAction, Reducer } from 'redux';

// The top-level state object
export interface ApplicationState {
    // counter: Counter.CounterState | undefined;
    // weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
    data: Data.DataStoreState | undefined;
}

export type KnownActions = Data.KnownAction;

//Converts Top-Level State to a matching 'ReducerState'
type ReducerState<T> = {
    [K in keyof T]: T[K] extends infer R | undefined ? Reducer<R, KnownActions> : never;
};

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers: ReducerState<ApplicationState> = {
    // counter: Counter.reducer,
    //weatherForecasts: WeatherForecasts.reducer
    data: Data.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
