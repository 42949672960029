import React, { useMemo } from "react";
import classNames from "classnames";
import { FormGroup, Label, Nav, NavItem } from "reactstrap";
import { EditorFieldBaseProps } from ".";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ChickasawWords, EnglishWords } from "../../../../store/data";
import { BasicSort, getWordLabel } from "../../../helpers";
import { useSelector } from "react-redux";
import { selectChickasawWords, selectDuplicateRecords, selectEnglishWords, selectWhichTab } from "../../../../store/selectors";
import RichTextEditor from "react-rte";

export const ID_TYPE = "id";

interface IDDisplayProps {
    value: string;
    style?: React.CSSProperties;
}

export const IDDisplay: React.FC<IDDisplayProps> = ({ style, value }) => {
    return (
        <span
            className={classNames("td", ID_TYPE)}
            style={style}
        >{value}</span>
    );
};

interface IDEditorProps {
    // value: string | null;
    record: ChickasawWords | EnglishWords;
}

export const IDEditor: React.FC<IDEditorProps> = ({ record }) => {
    //Display NAVTABS for this ID and duplicate IDs
    const location = useLocation();
    const rootUrl = useMemo(() => {
        const urlPathParts = location.pathname.split("/");
        urlPathParts.pop();
        const result = urlPathParts.join("/");
        return result;
    }, [location]);

    const duplicateRecords = useSelector(selectDuplicateRecords(location, record));

    const sortedRecords = useMemo(() => {
        if (record == null) {
            return [];
        }

        let result = [record];

        if (duplicateRecords != null && duplicateRecords.length > 0) {
            result = result.concat(duplicateRecords);
        }

        result.sort((a, b) => {
            const aHide = a.hide != null && (a.hide == 1 || a.hide.toString().toLowerCase() == "true");
            const bHide = b.hide != null && (b.hide == 1 || b.hide.toString().toLowerCase() == "true");
            if (aHide == bHide) {
                return a.id - b.id;
            }
            if (aHide) {
                return 1;
            }
            return -1;
        });

        return result;

    }, [record, duplicateRecords]);

    return (
        <Nav vertical tabs className="id_duplicates_tabs">
            <span className="duplicates_header">Duplicates</span>
            {sortedRecords.map((entry, idx) => {
                let wordDisplay = getWordLabel(entry); //entry.word;
                const wordResult = RichTextEditor.createValueFromString(wordDisplay, "markdown").toString("html");

                let transDisplay = "";
                let transResult = "";
                if ("Translated" in entry
                    && entry.Translated?.length > 0
                    && entry.Translated[0]?.word?.length > 0) {
                    transDisplay = getWordLabel(entry.Translated[0]);

                    if (entry.Translated.length > 1) {
                        for (var i = 1; i < entry.Translated.length; i++) {
                            var thisRes = getWordLabel(entry.Translated[i]);
                            if (thisRes.length > 0) {
                                transDisplay += ", " + thisRes;
                            }
                        }
                    }
                }
                transResult = transDisplay.replace(/\*/g, "");
                return (
                    <NavItem key={idx}>
                        <NavLink
                            to={rootUrl + "/" + entry.id}
                            className="nav-link"
                        >
                            {(entry.hide != null && (entry.hide == 1 || entry.hide.toString().toLowerCase() == "true") ? (
                                <span className="record_hidden">hidden</span>
                            ) : "")}
                            <span className="record_id">{entry.id}</span>
                            {wordResult.length > 0 ? (
                                <span className="record_word" dangerouslySetInnerHTML={{ __html: wordResult }} />
                            ) : null}
                            {transResult.length > 0 ? (
                                <span className="record_trans">{transResult}</span>
                            ) : null}
                        </NavLink>
                    </NavItem>
                );
            })}
        </Nav>
    );
};
