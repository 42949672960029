import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectIsApiHot, selectIsAuthorized, selectIsApiRequestPending } from '../store/selectors';
import { Login } from './auth/login';
import { EDITOR_CHICKASAW_URL } from './editor/editor';

export const HOME_URL = "/";

export const Home: React.FC = () => {
    const isApiHot = useSelector(selectIsApiHot);
    const isAuthorized = useSelector(selectIsAuthorized);
    const isApiRequestPending = useSelector(selectIsApiRequestPending);
    const history = useHistory();
    useEffect(() => {
        if (isAuthorized) {
            history.push(EDITOR_CHICKASAW_URL);
        }
    }, [isAuthorized]);
    if (isApiRequestPending || !isApiHot || isAuthorized) {
        //if the api has not responded,
        //or it has responded and we are already authorized,
        //show a loading message
        return (
            <div className="debug_notice">Loading...</div>
        );
    }
    return (
        <Login />
    );
}
