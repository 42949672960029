import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { actionCreators } from '../../store/data';
import { HOME_URL } from '../Home';

export const LOGOUT_URL = '/logout';

export const Logout: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        //dispatch({ type: 'LOGOUT' });
        dispatch(actionCreators.submitLogout());
        history.push(HOME_URL);
    }, []);
    return null;
}
