import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router";
import { Link, NavLink, useHistory } from "react-router-dom";
import Select from "react-select";
import { FixedSizeList } from "react-window";
import { Row, Col } from "reactstrap";
import AutoSizer from 'react-virtualized-auto-sizer';
import classNames from "classnames";
import { ChickasawWords, EnglishWords } from "../../store/data";
import { BasicSort, GetColumnFriendlyName } from "../helpers";
import { MenuList } from "./menuList";
import { EditorPanel } from "./editorPanel";
import { CHICKASAW_COLS, ColumnEditorTypeMap, ENGLISH_COLS } from "./list/fields";
import { WordListRow } from "./list/wordListRow";
import { EDITOR_ENGLISH_URL, EDITOR_ENGLISH_URL_ID, EDITOR_CHICKASAW_URL, EDITOR_CHICKASAW_URL_ID, EDITOR_ENGLISH_ADD_NEW_URL, EDITOR_CHICKASAW_ADD_NEW_URL } from "./editor";
import { useSelector } from "react-redux";
import { selectIsApiHot } from "../../store/selectors";
import { SelectOption } from "./selectOption";
import { AddNew } from "./addNew";

export interface SelectListValue<T extends string = string> {
    value: number;
    label: T;
    tags?: string[];
    record?: ChickasawWords | EnglishWords;
}

const SelectListEmptyValue: SelectListValue = { label: "Search...", value: -1 };

type WordFilterNames = "All Words" | "Words with Duplicates" | "Uppercase Words" | "Numbers";

const WordFilterSelectValue: SelectListValue<WordFilterNames>[] = [
    {
        label: "All Words",
        value: 1,
    },
    {
        label: "Words with Duplicates",
        value: 2,
    },
    {
        label: "Uppercase Words",
        value: 3,
    },
    {
        label: "Numbers",
        value: 4,
    },
];

type WordTabProps = {
    wordData: EnglishWords[] | null | undefined;
    fieldData: ColumnEditorTypeMap<keyof EnglishWords>[];
} | {
    wordData: ChickasawWords[] | null | undefined;
    fieldData: ColumnEditorTypeMap<keyof ChickasawWords>[];
};

export const WordTab: React.FC<WordTabProps> = ({ wordData, fieldData }) => {
    const [activeRecordRow, setActiveRecordRow] = useState<ChickasawWords | EnglishWords | null>(null);
    const isApiHot = useSelector(selectIsApiHot);

    const history = useHistory();
    const location = useLocation();

    const editorPanelRouteMatch = useRouteMatch<{ id: string }>(EDITOR_CHICKASAW_URL_ID);
    const editorPanelEnglishRouteMatch = useRouteMatch<{ id: string }>(EDITOR_ENGLISH_URL_ID);
    const activeRecordId = editorPanelRouteMatch?.params.id ?? editorPanelEnglishRouteMatch?.params.id ?? "";
    const isEditorPanelOpen = activeRecordId.length > 0;

    const urlPathParts = location.pathname.split("/");
    let closeEditorPanelUrl = "";
    if (isEditorPanelOpen) {
        urlPathParts.pop();
        closeEditorPanelUrl = urlPathParts.join("/");
    }

    const [activeRecordSelectValue, setActiveRecordSelectValue] = useState<SelectListValue>(SelectListEmptyValue);
    const [activeFilterSelectValue, setActiveFilterSelectValue] = useState<SelectListValue<WordFilterNames>>(WordFilterSelectValue[0]);

    const [gridStyle, setGridStyle] = useState({} as React.CSSProperties);
    const gridEl = useRef<HTMLDivElement>(null);
    const listInnerRef = useRef<HTMLElement>(null);
    const listRef = useRef<any>(null);

    const [sortColumn, setSortColumn] = useState<keyof ChickasawWords | keyof EnglishWords>("word_plain");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

    //if we navigate to a new url, reset the sorting if sortColumn no longer exists
    useEffect(() => {
        if (!fieldData.some((val: ColumnEditorTypeMap<keyof EnglishWords> | ColumnEditorTypeMap<keyof ChickasawWords>) => val.label == sortColumn)) {
            setSortColumn("word_plain");
            setSortDirection("asc");
        }
    }, [location.hash, sortColumn, fieldData]);

    const deDupedWordData = useMemo(() => {
        const wordsDeduped: (EnglishWords | ChickasawWords)[] = [];
        // also collect a list of duplicate (name-based) entries to assist with de-duplication
        const wordDupes: Record<string, any> = {};

        if (wordData && wordData.length) {
            for (var wordRow of wordData) {
                let target = wordDupes[wordRow.word];
                if (target == null || target.length === 0) {
                    wordDupes[wordRow.word] = [wordRow as any];
                    wordsDeduped.push(wordRow as any);
                }
            }
        }

        return wordsDeduped;
    }, [wordData]);

    //pre-generate each 'filter' list (to be able to hide filters that return empty results)
    const filteredWordData = useMemo(() => {
        let value: Record<WordFilterNames, (ChickasawWords | EnglishWords)[]> = {} as any;
        for (const filter of WordFilterSelectValue) {
            switch (filter.label) {
                case "Numbers":
                    value[filter.label] = deDupedWordData.filter((val) => val.word[0] >= '0' && val.word[0] <= '9');
                    break;
                case "Uppercase Words":
                    value[filter.label] = deDupedWordData.filter((val) => val.word[0] >= 'A' && val.word[0] <= 'Z');
                    break;
                case "Words with Duplicates":
                    value[filter.label] = deDupedWordData.filter((val) => val.duplicates.length);
                    // value[filter.label] = deDupedWordData;
                    break;
                case "All Words":
                default:
                    value[filter.label] = deDupedWordData;
                    break;
            }
        }

        return value;
    }, [deDupedWordData]);

    const sortedWordData = useMemo(() => {
        const sorted = filteredWordData[activeFilterSelectValue.label].sort(BasicSort(sortColumn as any));

        if (sortDirection == "asc") {
            return sorted;
        } else {
            sorted.reverse();
            return sorted;
        }
    }, [filteredWordData, activeFilterSelectValue, sortColumn, sortDirection]);

    const selectOptions = useMemo(() => sortedWordData.map((word) => ({
        value: word.id,
        label: word.word,
        record: word,
        tags: [], // TODO: add 'tags',
    })), [sortedWordData, activeFilterSelectValue, sortColumn, sortDirection]);

    const jumpToLetters = useMemo(() => {
        var pfStart = performance.now();

        const includedLetters: string[] = [];

        const alphabet = [..."abcdefghijklmnopqrstuvwxyz"];
        const decimals = [..."0123456789"];
        const binaries = ["true", "false"];

        const groupsToInclude: string[][] = [];

        const field = (fieldData as ColumnEditorTypeMap[]).find((fld) => fld.label == sortColumn);
        if (field) {
            switch (field.type) {
                case "boolean":
                    // includedLetters.push(...binaries);
                    groupsToInclude.push(binaries);
                    break;
                case "unique":
                    groupsToInclude.push(binaries);
                    groupsToInclude.push(decimals);
                    groupsToInclude.push(alphabet);
                    break;
                case "number":
                    groupsToInclude.push(decimals);
                    break;
                case "markdown":
                case "string":
                default:
                    groupsToInclude.push(decimals);
                    groupsToInclude.push(alphabet);
                    break;
            }
        } else {
            groupsToInclude.push(binaries);
            groupsToInclude.push(decimals);
            groupsToInclude.push(alphabet);
        }

        let letterDisplay: {
            char: string,
            idx: number,
        }[] = [];

        var pfGroups = performance.now();

        for (const grp of groupsToInclude) {
            let grpHasHit = false;

            const groupData: {
                char: string,
                idx: number,
            }[] = [];
            for (const char of grp) {
                if (grpHasHit) {
                    break;
                }
                let idx = -1;
                // check a string
                let eqCheck = (target: any) => target && target[sortColumn] && target[sortColumn].length && target[sortColumn].toString().toLowerCase() == char;
                if (char.length === 1) {
                    //checking a single char against a number or letter
                    eqCheck = (target) => target && target[sortColumn] && target[sortColumn].length && target[sortColumn][0].toString().toLowerCase() == char;
                }
                idx = sortedWordData.findIndex(eqCheck);
                // if (char.length === 1) {
                //     //checking a single char against a number or letter
                //     idx = sortedWordData.findIndex((val) => {
                //         const target = (val as any)[sortColumn];
                //         if (target) {
                //             if (target.length > 0) {
                //                 return target[0].toString().toLowerCase() == char;
                //             }
                //         }
                //         return false;
                //     });
                // } else {
                //     //checking a string against 'true' or 'false'
                //     idx = sortedWordData.findIndex((val) => {
                //         const target = (val as any)[sortColumn];
                //         if (target) {
                //             if (target.length > 0) {
                //                 return target.toString().toLowerCase() == char;
                //             }
                //         }
                //         return false;
                //     });
                // }
                groupData.push({ char, idx });
                if (idx !== -1) {
                    //it's a hit, make sure we know the group has a populated value
                    grpHasHit = true;
                }
            }

            if (grpHasHit) {
                letterDisplay.push(...groupData);
            }
        }

        var pfGroupEnd = performance.now();

        //TODO: refactor these: instead of these searches, just keep each group separate initially, then only add to result if a idx hits (will also cover alpha chars & be generic)
        //check if 'true'/'false' values are empty
        if (includedLetters.some((ltr) => ltr == "true" || ltr == "false")) {
            if (!letterDisplay.some((ltr) => ltr.idx !== -1 && (ltr.char == "true" || ltr.char == "false"))) {
                letterDisplay = letterDisplay.filter((ltr) => !(ltr.char == "true" || ltr.char == "false"));
            }
        }

        var pfTrueFalseEnd = performance.now();

        //check if 'numbers' filters are empty
        if (!letterDisplay.some((ltr) => ltr.idx !== -1 && (ltr.char.charCodeAt(0) >= 48 && ltr.char.charCodeAt(0) <= 57))) {
            //remove them from the list
            letterDisplay = letterDisplay.filter((ltr) => !(ltr.char.charCodeAt(0) >= 48 && ltr.char.charCodeAt(0) <= 57));
        }

        var pfEnd = performance.now();

        const first = pfGroups - pfStart;
        const groups = pfGroupEnd - pfGroups;
        const trufal = pfTrueFalseEnd - pfGroupEnd;
        const final = pfEnd - pfTrueFalseEnd;
        const total = pfEnd - pfStart;

        // console.log("jumpToLetters Parsing",
        //     first.toFixed(2) + "ms,",
        //     groups.toFixed(2) + "ms,",
        //     trufal.toFixed(2) + "ms,",
        //     final.toFixed(2) + "ms,",
        //     "Total:",
        //     total.toFixed(2) + "ms",
        // );

        return letterDisplay;
    }, [sortedWordData, activeFilterSelectValue, sortColumn, sortDirection, fieldData]);

    const handleItemSelectChangeCb = useCallback((value: any, action) => {
        const val = value?.value ?? -1;
        if (val > -1) {
            // setActiveRecordId(value?.value ?? -1);
            history.push(location.pathname + "/" + val);
        }
    }, [location]);

    const handleFilterSelectChangeCb = useCallback((value: any, action) => {
        setActiveFilterSelectValue(value!);
    }, []);

    const jumpToCb = useCallback((idx: number) => (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        if (listRef.current && idx > -1) {
            listRef.current.scrollToItem(idx, "start");
        }
    }, []);

    // find the active record and store it conveniently whenever the Id changes
    useEffect(() => {
        let match: EnglishWords | ChickasawWords | null = null;
        let matchedId: number = -1;

        if (wordData && +activeRecordId >= 0) {
            match = (wordData as any[]).find((val, idx) => val.id == activeRecordId);
            matchedId = match?.id ?? -1;
        }

        const selectVal = selectOptions.find((val) => val.value == matchedId) ?? SelectListEmptyValue;

        setActiveRecordSelectValue(selectVal);
        setActiveRecordRow(match);
    }, [activeRecordId, wordData, selectOptions]);

    //calculate grid height (temporary styling to expand content area)
    useEffect(() => {
        if (gridEl.current != null) {
            const currentEl = gridEl.current;
            const setHeight = (ev: any = null) => {
                const bounds = currentEl.getBoundingClientRect();
                setGridStyle({
                    height: (window.innerHeight - bounds.y - 20).toString() + "px"
                });
            }
            setHeight();
            // var sizer = (ev: any) => {
            //     //TODO: Add debouncer if this stays
            //     setHeight();
            // };
            window.addEventListener("resize", setHeight);

            return () => {
                window.removeEventListener("resize", setHeight);
            }
        }
    }, [wordData, location]);

    // Clicking on columns sorts the list
    const handleColumnClickCb = useCallback((val: keyof ChickasawWords | keyof EnglishWords) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        if (sortColumn.replace("_plain", "") === val) {
            // user clicked on column already being sorted on, so invert the list
            setSortDirection((sort) => sort === "asc" ? "desc" : "asc");
        } else {
            // user clicks on a new column to sort
            if (val == "word") {
                setSortColumn("word_plain");
            } else {
                setSortColumn(val);
            }
            setSortDirection("asc");
        }
    }, [sortColumn]);

    const [wordListHeaderStyles, setWordListHeaderStyles] = useState<React.CSSProperties | undefined>();

    // scroll to top of list when sort order/direction changes
    useLayoutEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTo(0);
        }

        // adjust 'header' row to pad for the scroll bar
        //TODO: find out a way to make this less hacky
        // possibly create an interval fn that checks for existence of right padding every x#ms and re-calcs until exists?
        const toFn = setTimeout(() => {
            const target = listInnerRef.current;
            if (target) {
                setWordListHeaderStyles({
                    paddingRight: (target.parentElement!.offsetWidth - target.parentElement!.clientWidth) + "px",
                });
            }
        }, 10);

        return () => {
            //clear previous fn's, only run most recent
            clearTimeout(toFn);
        };
    }, [sortedWordData, activeFilterSelectValue, sortDirection, sortColumn, location.hash, isApiHot]);

    const editorPanelRender = useMemo(() => () => (
        <div className="editor_panel_wrapper">
            <NavLink className="btn btn-danger" to={closeEditorPanelUrl}>×</NavLink>
            <EditorPanel
                fieldData={fieldData}
                record={activeRecordRow!}
            />
        </div>
    ), [closeEditorPanelUrl, fieldData, activeRecordRow]);

    const addNewRender = useMemo(() => () => (
        <div className="add_new_wrapper">
            <NavLink className="btn btn-danger" to={closeEditorPanelUrl}>×</NavLink>
            <AddNew
                fieldData={fieldData}
                record={activeRecordRow!}
            />
        </div>
    ), [closeEditorPanelUrl, fieldData, activeRecordRow]);

    return (<React.Fragment>
        <Switch>
            <Route path={EDITOR_ENGLISH_ADD_NEW_URL} render={addNewRender} />
            <Route path={EDITOR_CHICKASAW_ADD_NEW_URL} render={addNewRender} />
            <Route path={EDITOR_ENGLISH_URL_ID} render={editorPanelRender} />
            <Route path={EDITOR_CHICKASAW_URL_ID} render={editorPanelRender} />
        </Switch>
        <Row hidden={isEditorPanelOpen}>
            <Col>
                <div>
                    <Select
                        options={selectOptions}
                        components={{ MenuList, Option: SelectOption }}
                        onChange={handleItemSelectChangeCb}
                        value={activeRecordSelectValue}
                        defaultValue={SelectListEmptyValue}
                    />
                </div>
            </Col>
            <Col style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>
                    <Select
                        options={WordFilterSelectValue.filter((val) => filteredWordData[val.label].length > 0)}
                        // components={{ MenuList }}
                        onChange={handleFilterSelectChangeCb}
                        value={activeFilterSelectValue as any}
                        isSearchable={false}
                    />
                </div>
                <div>
                    <NavLink
                        to={`${location.pathname}/add`}
                        className="btn-add"
                        title="Add new word"
                    >
                        +
                    </NavLink>
                </div>
            </Col>
        </Row>
        <div hidden={isEditorPanelOpen}>
            {/* <Row>
                <Col>
                    <div className="jump-to-row">
                        <div>Jump to...</div>
                        <div className="jump-to-letters">{jumpToLetters.map(({ char, idx }, cnt) => (
                            <button
                                key={cnt}
                                disabled={idx === -1}
                                onClick={jumpToCb(idx)}
                                className="jump-to-button"
                            >{char}</button>
                        ))}</div>
                    </div>
                </Col>
            </Row> */}
            <Row>
                <Col>
                    <div className="wordListHeader" style={wordListHeaderStyles}>
                        {(fieldData as ColumnEditorTypeMap[]).map((val, idx) => {
                            if (!val.display_in_list) {
                                return null;
                            }
                            return (
                                <a
                                    key={idx}
                                    href="#"
                                    className={classNames("th", val.label, val.type, sortDirection, {
                                        "sort-col": sortColumn.replace("_plain", "") == val.label,
                                    })}
                                    onClick={handleColumnClickCb(val.label as any)}
                                    style={val.listStyles}
                                >
                                    {GetColumnFriendlyName(val.label)}
                                </a>
                            );
                        })}
                    </div>
                    <div ref={gridEl} style={gridStyle}>
                        <AutoSizer>
                            {({ height, width }) => (
                                <div className="wordListBody">
                                    <FixedSizeList
                                        height={height}
                                        width={width}
                                        itemCount={sortedWordData.length ?? 0}
                                        itemSize={40}
                                        itemData={{ sortedWordData, fieldData }}
                                        ref={listRef}
                                        innerRef={listInnerRef}
                                    >
                                        {/* {tableListRow} */}
                                        {WordListRow}
                                    </FixedSizeList>
                                </div>
                            )}
                        </AutoSizer>
                    </div>
                </Col>
            </Row>
        </div>
    </React.Fragment>);
};
