import { ApplicationState } from ".";
import * as H from "history";
import { ChickasawWords, EnglishWords } from "./data";

export const selectIsApiHot = (state: ApplicationState) => !!state.data?.apiReceived;

export const selectIsAuthorized = (state: ApplicationState) => !!state.data?.isAuthorized;

export const selectIsApiRequestPending = (state: ApplicationState) => !!state.data?.apiRequestPending;

export const selectWordRequestSucceeded = (state: ApplicationState) => state.data?.wordRequestSucceeded;

export const selectChickasawWords = (state: ApplicationState) => state.data?.chickasaw_words;

export const selectEnglishWords = (state: ApplicationState) => state.data?.english_words;

export const selectCreatedWordId = (state: ApplicationState) => state.data?.createdWordIndex;

export const selectWhichTab = (location: H.Location) => {
    if (location.pathname == null) {
        return "Chickasaw";
    }
    if (location.pathname.toLowerCase().indexOf("words/english") > -1) {
        return "English";
    }

    return "Chickasaw";
}

export const selectDuplicateRecords = (location: H.Location, record: ChickasawWords | EnglishWords) => (state: ApplicationState) => {
    if (location == null || location.pathname == null) {
        return null;
    }

    if (record == null 
        || record.id < 1
        || record.duplicates == null
        || record.duplicates.length == 0
        || record.duplicates[0] == null
        ) {
        return null;
    }

    const isChicaksaw = selectWhichTab(location) == "Chickasaw";

    const sameRecords: (ChickasawWords | EnglishWords)[] = isChicaksaw
        ? selectChickasawWords(state)!
        : selectEnglishWords(state)!;

    const dupeRecords = sameRecords.filter(m => record.duplicates.some(n => n == m.id));

    return dupeRecords;
};
