import React, { useCallback, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { EditorFieldBaseProps } from ".";
import { GetColumnFriendlyName, GetDateFromString, GetDateString } from "../../../helpers";

import "react-datepicker/dist/react-datepicker.css";

export const DATEPICKER_TYPE = "datepicker";

// interface IDDisplayProps {
//     value: string;
//     style?: React.CSSProperties;
// }

// export const DatepickerDisplay: React.FC<IDDisplayProps> = ({ style, value }) => {
//     return (
//         <span
//             className={classNames("td", DATEPICKER_TYPE)}
//             style={style}
//         >{value}</span>
//     );
// };

interface DatepickerEditorProps extends EditorFieldBaseProps {
    value: string | null;
    handleChange: (val: { currentTarget: { value: any, checked?: boolean, name: string } }) => void;
}

export const DatepickerEditor: React.FC<DatepickerEditorProps> = ({ handleChange, label, value }) => {
    const dateValue = useMemo(() => GetDateFromString(value ?? ""), [value]);

    const handleChangeCb = useCallback((
        date: Date | [Date, Date] | null,
        event: React.SyntheticEvent<any, Event> | undefined,
    ) => {
        let dateVal: any = date;
        if (dateVal != null) {
            let now = new Date();
            let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            if (dateVal < today) {
                return;
            }
            dateVal = GetDateString(dateVal);
        }
        let changeObj = { currentTarget: {
            value: dateVal, //null or a Date string "mm-dd-yyyy"
            name: label,
        }};
        handleChange(changeObj);
    }, []);

    // const [startDate, setStartDate] = useState<Date>(new Date());

    return (
        <FormGroup tag={Row}>
            <Col sm={2}>
                <Label className="col-form-label" for={label}>{GetColumnFriendlyName(label)}</Label>
            </Col>
            <Col sm={10}>
                <DatePicker
                    showPreviousMonths={false}
                    selected={dateValue}
                    onChange={handleChangeCb}
                    dateFormat="MM/dd"
                />
            </Col>
        </FormGroup>
    );
};
