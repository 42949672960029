import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Button, Col, Form, Row } from "reactstrap";
import { actionCreators, ChickasawWords, EnglishWords, WordFormData } from "../../store/data";
import { selectCreatedWordId, selectWhichTab } from "../../store/selectors";
import { BooleanEditor, ColumnEditorTypeMap, DatepickerEditor, MarkdownEditor, NumberEditor, PartOfSpeechEditor, StringEditor, UniqueEditor } from "./list/fields";

interface AddNewProps {
    fieldData: ColumnEditorTypeMap[];
    record: ChickasawWords | EnglishWords;
    // type: "Chickasaw" | "English";
}

export const AddNew: React.FC<AddNewProps> = ({ fieldData, record }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const createWordSuccess = useSelector(selectCreatedWordId);
    const currentTab = useMemo(() => selectWhichTab(location), [location]);
    const [columnValues, setColumnValues] = useState<any[]>(new Array(fieldData.length));
    const [modifiedFields, setModifiedFields] = useState<any[]>([]);

    const [formDisabled, setFormDisabled] = useState(false);

    useEffect(() => {
        if (createWordSuccess == null || createWordSuccess <= 0) {
            return;
        }
        if (formDisabled) {
            history.push(`/words/${currentTab.toLowerCase()}/${createWordSuccess}`);
            dispatch({ type: 'WORD_CREATE_SUCCESS', id: -1 });
        }
    }, [createWordSuccess, formDisabled, history]);

    const handleChange = useCallback(
        (idx: number) => (val: { currentTarget: { value: any, checked?: boolean, name: string } }) => {
            const currentTarget = val.currentTarget;
            let newValue = currentTarget.value;
            if (newValue == "on") {
                newValue = currentTarget.checked ? 1 : 0;
            }

            setColumnValues((oldVal) => {
                const newValues = [...oldVal];
                if (idx >= 0) {
                    newValues[idx] = newValue;
                }
                return newValues;
            });

            setModifiedFields((fld) => ({ ...fld, [currentTarget.name]: true }));
        }, [columnValues]);

    const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setFormDisabled(true);

        const submitWordModel: WordFormData = {
            id: -1,
            isChickasaw: currentTab == "Chickasaw",
            fields: [],
            files: {},
        };

        for (const field in modifiedFields) {
            if ((modifiedFields as any)[field] === true) {
                let recordIdx = fieldData.indexOf(fieldData.find((col) => col.label == field)!);

                const value = columnValues[recordIdx];
                submitWordModel.fields!.push({
                    key: field as any,
                    value,
                });
            }
        }

        dispatch(actionCreators.createWord(submitWordModel));
    }, [fieldData, record, modifiedFields]);

    return (
        <div className="editor_panel">
            {/* <Row>
                <Col>
                    <h2>Add a New Word</h2>
                </Col>
            </Row> */}

            <Form onSubmit={handleSubmit}>
                <fieldset disabled={formDisabled}>
                    {fieldData.map((val, idx) => {
                        if (!val.display_in_editor) {
                            return;
                        }
                        switch (val.type) {
                            case "number":
                                return (
                                    <NumberEditor
                                        key={idx}
                                        label={val.label}
                                        value={columnValues[idx]}
                                        handleChange={handleChange(idx)}
                                        isModified={(modifiedFields as any)[val.label] === true}
                                        isEditable={val.editable}
                                    />
                                );
                            case "boolean":
                                return (
                                    <BooleanEditor
                                        key={idx}
                                        label={val.label}
                                        value={columnValues[idx]}
                                        handleChange={handleChange(idx)}
                                        isModified={(modifiedFields as any)[val.label] === true}
                                        isEditable={val.editable}
                                    />
                                );
                            case "unique":
                                return (
                                    <UniqueEditor
                                        key={idx}
                                        label={val.label as any}
                                        value={columnValues[idx]}
                                        handleChange={handleChange(idx)}
                                        isModified={(modifiedFields as any)[val.label] === true}
                                        isEditable={val.editable}
                                        record={record}
                                    />
                                );
                            case "markdown":
                                return (
                                    <StringEditor
                                        key={idx}
                                        label={val.label}
                                        value={columnValues[idx]}
                                        handleChange={handleChange(idx)}
                                        isModified={(modifiedFields as any)[val.label] === true}
                                        isEditable={val.editable}
                                    />
                                    // <MarkdownEditor
                                    //     key={idx}
                                    //     label={val.label}
                                    //     value={columnValues[idx]}
                                    //     handleChange={handleChange(idx)}
                                    //     isModified={(modifiedFields as any)[val.label] === true}
                                    //     isEditable={val.editable}
                                    // />
                                );
                            // case "audiofile":
                            //     return (
                            //         <AudioFileEditor
                            //             key={idx}
                            //             label={val.label}
                            //             value={columnValues[idx]}
                            //             handleChange={handleFileChangeCb(idx)}
                            //             isModified={(modifiedFields as any)[val.label] === true}
                            //             isEditable={val.editable}
                            //         />
                            //     );
                            // case "duplicates":
                            //     return (
                            //         <DuplicatesEditor
                            //             key={idx}
                            //             label={val.label}
                            //             value={columnValues[idx]}
                            //             handleChange={handleChange(idx)}
                            //             isModified={(modifiedFields as any)[val.label] === true}
                            //             isEditable={val.editable}
                            //             record={record}
                            //         />
                            //     );
                            case "partofspeech":
                                return (
                                    <PartOfSpeechEditor
                                        key={idx}
                                        label={val.label}
                                        value={columnValues[idx]}
                                        handleChange={handleChange(idx)}
                                        isModified={(modifiedFields as any)[val.label] === true}
                                        isEditable={val.editable}
                                    />
                                );
                            case "string":
                                return (
                                    <StringEditor
                                        key={idx}
                                        label={val.label}
                                        value={columnValues[idx]}
                                        handleChange={handleChange(idx)}
                                        isModified={(modifiedFields as any)[val.label] === true}
                                        isEditable={val.editable}
                                    />
                                );
                            // case "id":
                            //     return (
                            //         <IDEditor
                            //             key={idx}
                            //             value={columnValues[idx]}
                            //             record={record}
                            //         />
                            //     );
                            case "datepicker":
                                return (
                                    <DatepickerEditor
                                        key={idx}
                                        label={val.label}
                                        value={columnValues[idx]}
                                        handleChange={handleChange(idx)}
                                        isModified={(modifiedFields as any)[val.label] === true}
                                        isEditable={val.editable}
                                    />
                                );
                            // case "translated":
                            //     return (
                            //         <TranslatedEditor
                            //             key={idx}
                            //             label={val.label}
                            //             value={columnValues[idx]}
                            //             disabled={isFormDisabled}
                            //             record={record}
                            //             handleChange={customValueChangeCb}
                            //             isModified={(modifiedFields as any)[val.label] === true}
                            //             isEditable={val.editable}
                            //         />
                            //     )
                            default:
                                return; /*(
                                    <div>editorPanel::render: No Editor set for {val.label}</div>
                                );*/
                        }
                    })}
                </fieldset>
                <div className="form-group form-buttons">
                    <Button color="success" type="submit" disabled={formDisabled}>Create New Word</Button>
                </div>
            </Form>
        </div>
    );
};
