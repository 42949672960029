import { EnglishWords, ChickasawWords } from "../store/data";

export interface SelectValueOption {
    label: string;
    value: number;
}

const ColumnFriendlyNames: Partial<Record<keyof EnglishWords | keyof ChickasawWords, string>> = {
    "id": "ID",
    "wotd_date": "Word of the Day",
    "cn_word_pronunciation": "Pronunciation",
};

export const GetColumnFriendlyName = (val: string) => {
    if (val in ColumnFriendlyNames) {
        return (ColumnFriendlyNames as any)[val];
    }
    const spaceParts = val.split("_");
    if (spaceParts.length) {
        return spaceParts.join(" ");
    }
    return val;
};

export const RecordHasDuplicates = (record: EnglishWords | ChickasawWords) => {
    return record != null
        && "duplicates" in record
        && record.duplicates != null
        && record.duplicates.length > 0
        && record.duplicates[0] != null;
}

export const getWordLabel = (word: ChickasawWords | EnglishWords) => {
    var result = word.word; //.replace(/\*/g, "");

    if ("part_of_speech" in word && word.part_of_speech != null && word.part_of_speech.length > 0) {
        result += ", " + word.part_of_speech;
    }

    if ("notes" in word && word.notes != null && word.notes.length > 0) {
        result += " (" + word.notes + ")";
    }

    return result;
}

export const GetDateString = (date: Date): string => {
    //return date.getMonth() + "-" + date.getDate() + "-" + date.getFullYear();
    var mo = date.getMonth();
    var da = date.getDate();
    let result = "";

    if (mo < 10) {
        result += "0";
    }

    result += mo.toString() + "-";

    if (da < 10) {
        result += "0";
    }

    result += da.toString();

    return result;
}

export const GetDateFromString = (date: string): Date | null => {
    var now = new Date();
    if (date == null || !date.length) {
        return null;
    }

    var dateParts = date.split("-");
    if (dateParts.length == 2) {

        var parsedDate = new Date(now.getFullYear(), +dateParts[0], +dateParts[1]);

        if (now > parsedDate) {
            parsedDate = new Date(now.getFullYear() + 1, +dateParts[0], +dateParts[1]);
        }

        return parsedDate;

    } else if (dateParts.length == 3) {
        var parsedDate = new Date(+dateParts[2], +dateParts[0], +dateParts[1]);
        return parsedDate;
    }

    return null;
}

export function BasicSort<T extends Record<any, any>>(fieldName: keyof T) {
    return (a: T, b: T) => {
        switch (typeof a[fieldName]) {
            case "string":
                //compare lowercase versions of the strings
                //if lowercase version matches, compare original version instead
                return LowerCaseStringCompare(a[fieldName], b[fieldName]);
            default:
                if (Array.isArray(a[fieldName])) {
                    if (!(fieldName in b)) {
                        return 1;
                    }
                    if (!(fieldName in a)) {
                        return -1;
                    }
                    if (a[fieldName].length < b[fieldName].length) {
                        return -1;
                    }
                    if (a[fieldName].length > b[fieldName].length) {
                        return 1;
                    }
                    return 0;
                }

                if (a[fieldName] < b[fieldName]) {
                    return -1;
                }
                if (a[fieldName] > b[fieldName]) {
                    return 1;
                }
                return 0;
        }
    };
}

export const LowerCaseStringCompare = (a: string, b: string) => {
    if (!a || !b) {
        return 0;
    }
    const aLower = a.toLowerCase();
    const bLower = b.toLowerCase();

    if (aLower < bLower) {
        return -1;
    }
    if (aLower > bLower) {
        return 1;
    }
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};
