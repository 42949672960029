import React, { Component, useMemo } from "react";
import { FixedSizeList, FixedSizeListProps, ListChildComponentProps } from "react-window";
import { SelectListValue } from "./wordTab";

const height = 35;

// export class MenuList extends Component<MenuListComponentProps<SelectListValue, false>> {
//     render() {
//         const { options, children, maxHeight, getValue } = this.props;
//         const [value] = getValue();
//         const initialOffset = options.indexOf(value) * height;

//         return (
//             <FixedSizeList
//                 width={-1}
//                 height={isNaN(maxHeight) ? 0 : maxHeight}
//                 itemCount={(children! as any[]).length}
//                 itemSize={height}
//                 initialScrollOffset={initialOffset}
//             >
//                 {({ index, style }) => <div style={style}>{(children as any[])[index]}</div>}
//             </FixedSizeList>
//         );
//     }
// }

interface MenuListProps {
    options: string;
    children: any;
    maxHeight: number;
    getValue: () => any;
}

export const MenuList: React.FC<MenuListProps> = ({ options, children, maxHeight, getValue }) => {
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;
    return (
        <FixedSizeList
            width={-1}
            height={isNaN(maxHeight) ? 0 : maxHeight}
            itemCount={(children! as any[]).length}
            itemSize={height}
            initialScrollOffset={initialOffset}
        >
            {({ index, style }: { index: number; style: any }) => {
                // console.log(children[index]);
                return (
                    <div style={style}>{children[index]}</div>
                );
            }}
        </FixedSizeList>
    );
};
