import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { FormGroup, Label, Input, FormText, Row, Col, Button } from "reactstrap";
import { EditorFieldBaseProps } from ".";
import { ChickasawWords, EnglishWords } from "../../../../store/data";
import { GetColumnFriendlyName } from "../../../helpers";

interface AudioFileDisplayProps {
    value: string;
    style?: React.CSSProperties;
    record: ChickasawWords | EnglishWords;
}

export const AUDIOFILE_TYPE = "audiofile";

export const AudioFileDisplay: React.FC<AudioFileDisplayProps> = ({ record, value, style }) => {
    const isTrue = value && value != "false";
    const hasDuplicates = record.duplicates.length > 0;
    return (
        <span
            style={style}
            title={hasDuplicates ? "At least one instance is missing an audio file" : undefined}
            className={classNames("td", AUDIOFILE_TYPE, {
                "is-true": isTrue,
                "is-false": !isTrue,
                "has-duplicates": hasDuplicates,
            })}
        >{isTrue ? "✓" : "✕"}</span>
    );
};

interface AudioFileEditorProps extends EditorFieldBaseProps {
    label: string;
    value: string | null;
    handleChange: (file?: File) => void;
}

export const AudioFileEditor: React.FC<AudioFileEditorProps> = ({ label, value, handleChange, isEditable }) => {
    const parsedLabel = useMemo(() => GetColumnFriendlyName(label), [label]);
    const handleUploadCb: React.FormEventHandler<HTMLInputElement> = useCallback((ev: React.FormEvent<HTMLInputElement>) => {
        const target = ev.target as HTMLInputElement;
        if (target.files != null && target.files.length > 0) {
            handleChange(target.files[0]);
        }
    }, []);
    const removeAudioFile = useCallback(() => {
        handleChange();
    }, [handleChange]);
    return (
        <FormGroup tag={Row}>
            <Col sm={2}>
                <Label className="col-form-label" for={label}>{parsedLabel}</Label>
            </Col>
            <Col sm={5}>
                <Input
                    type="file"
                    name={label}
                    id={label}
                    disabled={!isEditable}
                    onChange={handleUploadCb}
                    accept=".mp3,.wav"
                />
                <FormText color="muted">
                    <span className="debug_notice">Upload a new audio file.</span>
                </FormText>
            </Col>
            <Col sm={5}>
                {value && value.length > 0 ? (
                    <React.Fragment>
                        <audio
                            className="existing-audio-file"
                            controls
                            src={value}
                        >Your browser does not support the <code>audio</code> element.
                        </audio>
                        <Button
                            color="danger"
                            className="btn-remove-audio"
                            onClick={removeAudioFile}
                            title="Remove Audio File"
                        >×</Button>
                        <FormText color="muted">
                            {value}
                        </FormText>
                        <div className="existing-url-spacer">
                            <FormText color="muted">
                                {value}
                            </FormText>
                        </div>
                    </React.Fragment>
                ) : null}
            </Col>
        </FormGroup>
    );
};
