import React from 'react';
import { Route } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { Logout, LOGOUT_URL } from './components/auth/logout';
import { AuthorizeRoute } from './components/auth/route';
import { Layout } from './components/Layout';
import { Home, HOME_URL } from './components/Home';
import { BASE_URL, Editor } from './components/editor/editor';

import 'react-toastify/dist/ReactToastify.css';

import './custom.scss';

/*
const oidcConfig = {
  onSignIn: () => {
    // Redirect?
    console.log("Signed in");
  },
  authority: window.location.origin, //env.NODE_ENV == "development" ? 'https://oidc.io/oauth' : '',
  clientId: '34613001e72fa23078b14c27b1a66d9e',
  redirectUri: window.location.origin, //'https://my-app.com/'
};
*/

export default () => {
    return (
        <Layout>
            <ToastContainer
                position="bottom-left"
                autoClose={3500}
                newestOnTop={true}
                pauseOnHover={true}
                closeOnClick={true}
            />
            {/* <AuthorizeManager /> */}
            {/* <AuthProvider {...oidcConfig}> */}
                <Route exact path={HOME_URL} component={Home} />
                <AuthorizeRoute exact path={LOGOUT_URL} component={Logout} />
                <AuthorizeRoute path={BASE_URL} component={Editor} />
            {/* </AuthProvider> */}
            {/*
            <Route path='/counter' component={HookCounter} />
            <Route path='/fetch-data/:startDateIndex?' component={FetchData} />
            */}
        </Layout>
    );
};
