import { useCallback, useState } from "react";

export const useToggle = (initialValue: boolean = false): [
    boolean,
    () => void,
] => {
    const [toggleVal, setToggleVal] = useState(initialValue);
    const toggleFunc = useCallback(() => {
        setToggleVal(toggleVal);
    }, [toggleVal]);

    return [
        toggleVal,
        toggleFunc
    ];
};
