import classNames from "classnames";
import React, { useMemo } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { EditorFieldBaseProps } from ".";
import { GetColumnFriendlyName } from "../../../helpers";

interface BooleanDisplayProps {
    value: string;
    style?: React.CSSProperties;
}

export const BOOLEAN_TYPE = "boolean";

export const BooleanDisplay: React.FC<BooleanDisplayProps> = ({ value, style }) => {
    const isTrue = value && value != "false";
    return (
        <span
            style={style}
            className={classNames("td", BOOLEAN_TYPE, {
                "is-true": isTrue,
                "is-false": !isTrue,
            })}
        >{isTrue ? "✓" : "✕"}</span>
    );
};

interface BooleanEditorProps extends EditorFieldBaseProps {
    value: boolean | string | null;
    handleChange: (val: React.ChangeEvent<HTMLInputElement>) => void;
}

export const BooleanEditor: React.FC<BooleanEditorProps> = ({ label, value, handleChange, isModified, isEditable }) => {
    const parsedLabel = useMemo(() => GetColumnFriendlyName(label), [label]);
    return (
        <FormGroup>
            <FormGroup check>
                <Label check for={label}>
                    <Input
                        type="checkbox"
                        name={label}
                        id={label}
                        checked={value == true || value?.toString().toLowerCase() == "true" || value == "1"}
                        onChange={handleChange}
                        disabled={!isEditable}
                    />
                    {parsedLabel}
                </Label>
            </FormGroup>
        </FormGroup>
    );
};
