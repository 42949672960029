import React, { useCallback, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Container, NavbarBrand, NavbarToggler, Collapse, NavItem } from "reactstrap";
import { LOGOUT_URL } from "./auth/logout";
import { HOME_URL } from "./Home";
import { useToggle } from "./hooks/useToggle";

export const NavMenu: React.FC = () => {
    const [isOpen, toggleIsOpen] = useToggle(false);
    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" dark>
                <Container>
                    <NavbarBrand tag={Link} to="/"><img src="/images/humes-title.svg" /></NavbarBrand>
                    <NavbarToggler onClick={toggleIsOpen} className="mr-2"/>
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink className="nav-link" to={HOME_URL}>Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link" to={LOGOUT_URL}>Logout</NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
}
