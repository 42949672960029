import React, { useEffect, useState } from 'react'
import { Component } from 'react'
import { Route, Redirect, RouteProps, useHistory, useRouteMatch } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './_constants'
import authService from './service'
import { useSelector } from 'react-redux'
import { selectIsApiHot, selectIsAuthorized } from '../../store/selectors'

export class AuthorizeRouteProps {

}

export const AuthorizeRoute: React.FC<AuthorizeRouteProps & RouteProps> = ({ component, exact, path, children }) => {
    const isAuthenticated = useSelector(selectIsAuthorized);
    const isApiHot = useSelector(selectIsApiHot);
    const isRouteActive = useRouteMatch(path!);
    const history = useHistory();

    useEffect(() => {
        if (isRouteActive && isApiHot && !isAuthenticated) {
            history.push("/");
        }
    }, [isRouteActive, isApiHot, isAuthenticated]);

    if (isApiHot && isAuthenticated) {
        return <Route component={component} exact={exact} path={path}>{children}</Route>;
    }

    return null;
};
