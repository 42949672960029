import React, { useCallback, useMemo } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { selectWhichTab } from "../../store/selectors";

interface MultiValueLabelProps {
    data: any;
    innerProps: any;
    selectProps: any;
}

const styles = {
    view: {
        padding: "2px 0 2px 6px",
    } as React.CSSProperties,
};

export const MultiValueLabel: React.FC<MultiValueLabelProps> = ({data, innerProps, selectProps, children}) => {
    const location = useLocation();
    const history = useHistory();
    const currentTab = useMemo(() => selectWhichTab(location), [location]);
    const targetUrl = useMemo(() => "/words/"
        + (currentTab == "Chickasaw" ? "english" : "chickasaw")
        + "/" + data.value
        , [currentTab, data, location]);
    const linkIsActive = useMemo(() => !selectProps.menuIsOpen, [selectProps]);
    const handleClick = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        if (linkIsActive) {
            history.push(targetUrl);
        }
    }, [targetUrl, linkIsActive]);
    return (
        <div style={styles.view}>
            <NavLink
                to={targetUrl}
                style={linkIsActive ? {
                    color: "#212529",
                    textDecoration: "none",
                } : {}}
                onClick={handleClick}
            >{children}</NavLink>
        </div>
    );
};
