import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import { EditorFieldBaseProps, StringEditor } from ".";
import { ChickasawWords, EnglishWords } from "../../../../store/data";
import { GetColumnFriendlyName } from "../../../helpers";
import { DuplicatesEditor } from "./duplicates";
import { StringDisplay } from "./string";

type UNIQUE_COLUMNS = "part_of_speech" | "duplicates";

export const UNIQUE_TYPE = "unique";

interface UniqueDisplayProps {
    value: any;
    label: UNIQUE_COLUMNS;
    style?: React.CSSProperties;
}

export const UniqueDisplay: React.FC<UniqueDisplayProps> = ({ value, label, style }) => {
    switch (label.toLowerCase()) {
        // case "id":
        //     return (
        //         <IDDisplay value={value} style={style} />
        //     );
        case "part_of_speech":
            return (
                <StringDisplay value={value} style={style} />
            );
        case "duplicates":
            return (
                <span
                    className={classNames("td", UNIQUE_TYPE, label)}
                    style={style}
                >{value?.length > 0 ? value.length : null}</span>
            );
        default:
            return (
                <div>UniqueDisplay::render: No display specified for this column</div>
            );
    }
};

interface UniqueEditorProps extends EditorFieldBaseProps {
    label: UNIQUE_COLUMNS;
    value: string | null;
    handleChange: (val: React.ChangeEvent<HTMLInputElement>) => void;
    record: ChickasawWords | EnglishWords;
}

export const UniqueEditor: React.FC<UniqueEditorProps> = ({ label, value, handleChange, isModified, isEditable, record }) => {
    const parsedLabel = useMemo(() => GetColumnFriendlyName(label), [label]);
    const [initialValue] = useState(value);
    switch (label) {
        case "part_of_speech":
            return <StringEditor
                label={label}
                value={value}
                handleChange={handleChange}
                isModified={isModified}
                isEditable={isEditable}
            />;
        // case "Translated":
        //     if (record && record.Translated && record.Translated.length) {
        //         return (
        //             <FormGroup>
        //                 <div className="translated_words">
        //                     <label>Translated Words</label>
        //                     {(record.Translated as any[]).map((val: EnglishWords | ChickasawWords, idx) => (
        //                         <button key={idx}>{val.word}</button>
        //                     ))}
        //                 </div>
        //             </FormGroup>
        //         );
        //     }
        //     break;
        // case "duplicates":
        //     if (record && record.duplicates && record.duplicates.length) {
        //         return (
        //             // <FormGroup tag={Row}>
        //             //     <Col sm={2}>
        //             //         <Label className="col-form-label" for={label}>{parsedLabel}</Label>
        //             //     </Col>
        //             //     <Col sm={10}>
        //             //         <Input
        //             //             type="text"
        //             //             name={label}
        //             //             id={label}
        //             //             placeholder={initialValue ?? ""}
        //             //             value={value ?? ""}
        //             //             onChange={handleChange}
        //             //             disabled={!isEditable}
        //             //         />
        //             //     </Col>
        //             // </FormGroup>
        //         );
        //     }
        default:
            return (
                <div>UniqueEditor: No editor defined for {label}</div>
            )
    }

    return null;
};
