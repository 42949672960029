import { toast } from "react-toastify";
import { KnownAction, ChickasawWords, EnglishWords, WordFormData, DataStoreState } from ".";
import { AppThunkAction } from "..";

export interface WordRequestResponseBody {
    english: DataStoreState["english_words"];
    chickasaw: DataStoreState["chickasaw_words"];
    error?: any | null;
}

export interface SubmitWordResponseBody {
    success: boolean;
}

export interface RequestWordListAction {
    type: 'REQUEST_DATA_WORDS';
}

export interface ConsumeWordResponseValueAction {
    type: 'CONSUME_RESPONSE_WORD';
}

export interface DeleteWordAction {
    type: 'DELETE_WORD';
}

export interface SubmitWordAction {
    type: 'SUBMIT_WORD';
}

export interface CreateWordSuccessAction {
    type: 'WORD_CREATE_SUCCESS';
    id: number;
}

export interface ReceiveWordAction {
    type: 'RECEIVE_RESPONSE_WORD';
    success: boolean;
}

export interface ReceiveWordListAction {
    type: 'RECEIVE_DATA_WORDS';
    english_words: EnglishWords[];
    chickasaw_words: ChickasawWords[];
}

export interface ApiRequestStartedAction {
    type: 'API_REQUEST_BEGIN';
}

export interface ApiRequestCompletedAction {
    type: 'API_REQUEST_END';
}

export const requestWords = (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState
        && appState.data
        && appState.data.isAuthorized
        && !appState.data.apiRequestPending
        // && !(appState.data.chickasaw_words || appState.data.english_words)
    ) {
        dispatch({ type: "API_REQUEST_BEGIN" });
        fetch("/Data/Words", {
            method: "POST",
        })
            .then(response => response.ok ? response.json() as Promise<WordRequestResponseBody> : { error: true } as WordRequestResponseBody)
            .then(data => {
                dispatch({ type: "API_REQUEST_END" });
                if (!data || data.error) {
                    dispatch({ type: 'RECEIVE_AUTH_CHECK', isAuth: false });
                } else {
                    let chickasaw_words: ChickasawWords[] = [];
                    let english_words: EnglishWords[] = [];

                    const enWords = data.english;
                    const cwWords = data.chickasaw;

                    if (cwWords && enWords) {
                        const cwMap: Record<number, ChickasawWords> = {};
                        const enMap: Record<number, EnglishWords> = {};

                        var start = performance.now();

                        for (const row of cwWords) {
                            //populate 'word_plain' for searching/sorting
                            row.word_plain = row.word.replace(/\*/g, "");

                            //parse duplicates
                            // row.Duplicates = cwWords.filter((val) => val.id != row.id && val.word.toLowerCase() == row.word.toLowerCase());

                            //populate id->obj map
                            if (!row.has_audio?.length) {
                                row.has_audio = "false";
                            }
                            cwMap[row.id] = row;
                        }

                        var cwParsed = performance.now();

                        for (const row of enWords) {
                            //populate 'word_plain' for searching/sorting
                            row.word_plain = row.word.replace(/\*/g, "");

                            //parse duplicates
                            // row.Duplicates = enWords.filter((val) => val.id != row.id && val.word.toLowerCase() == row.word.toLowerCase());

                            //grab FK objects
                            if (row.fKs != null && row.fKs.length > 0) {

                                const parts = row.fKs.split(",");
                                if (parts?.length) {
                                    const FKobjs = [];
                                    for (const part of parts) {
                                        FKobjs.push(cwMap[+part]);
                                    }
                                    row.Translated = FKobjs;
                                }
                            }

                            //populate id->obj map
                            if (!row.has_audio?.length) {
                                row.has_audio = "false";
                            }
                            enMap[row.id] = row;
                        }

                        var enParsed = performance.now();

                        for (const row of cwWords) {
                            //grab FK objects now that enMap is populated
                            if (row.fKs != null && row.fKs.length > 0) {
                                const parts = row.fKs.split(",");
                                if (parts?.length) {
                                    const FKobjs = [];
                                    for (const part of parts) {
                                        FKobjs.push(enMap[+part]);
                                    }
                                    row.Translated = FKobjs;
                                }
                            }
                        }

                        var fnParsed = performance.now();

                        const cwDur = cwParsed - start;
                        const enDur = enParsed - cwParsed;
                        const fnDur = fnParsed - enParsed;
                        const total = fnParsed - start;

                        // console.log("Data Received Parsing",
                        //     cwDur.toFixed(2) + "ms,",
                        //     enDur.toFixed(2) + "ms,",
                        //     fnDur.toFixed(2) + "ms,",
                        //     "Total:",
                        //     total.toFixed(2) + "ms",
                        // );

                        chickasaw_words = cwWords;
                        english_words = enWords;
                    }

                    dispatch({ type: 'RECEIVE_DATA_WORDS', chickasaw_words, english_words });
                }
            });
    } else {
        dispatch({ type: "AUTH_INVALID" });
    }
};

export const requestStatus = (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: "API_REQUEST_BEGIN" });
    fetch("/Data/Status", {
        method: "POST",
    })
        .then(response => response.ok ? response.json() as Promise<boolean> : false)
        .then(data => {
            dispatch({ type: "API_REQUEST_END" });

            // data = true; //TODO: remove (debugging)
            dispatch({ type: 'RECEIVE_AUTH_CHECK', isAuth: !!data });

            if (!!data) {
                //if we are going from not-auth'd to auth'd
                dispatch(requestWords() as any);
            }
        });
};

export const deleteWord = (formData: WordFormData): AppThunkAction<KnownAction> => (dispatch, getState) => {
    if (formData == null || formData.id == null || formData.id < 1) {
        return;
    }
    const appState = getState();
    if (appState && appState.data) {
        if (!appState.data.isAuthorized) {
            return;
        }

        const requestBody = JSON.stringify(formData);
        fetch("/Data/DeleteWord", {
            method: "POST",
            body: requestBody,
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
        })
            .then(response => response.ok ? response.json() as Promise<SubmitWordResponseBody> : { success: false } as SubmitWordResponseBody)
            .then(data => {
                dispatch({ type: 'RECEIVE_RESPONSE_WORD', success: !!data.success });
                dispatch(requestWords() as any);
                if (!data.success) {
                    console.log("deleteWord failure", data);
                    return;
                }
                // toast("Word updated successfully!");
            });
    }
};

export const createWord = (formData: WordFormData): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.data) {
        if (!appState.data.isAuthorized) {
            return;
        }

        let noFileFormData = { ...formData, "files": null };

        const requestBody = JSON.stringify(noFileFormData);
        fetch("/Data/CreateWord", {
            method: "POST",
            body: requestBody,
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
        })
            .then(response => response.ok ? response.json() as Promise<number> : -1)
            .then(data => {
                dispatch({ type: 'RECEIVE_RESPONSE_WORD', success: data > -1 });
                dispatch(requestWords() as any);
                dispatch({ type: 'WORD_CREATE_SUCCESS', id: data});
                // toast("Word updated successfully!");
            });
    }
};

export const submitWord = (formData: WordFormData): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.data) {
        if (!appState.data.isAuthorized) {
            return;
        }

        const submitJsonInfo = (newAudioUrl: string | null = null) => {
            let noFileFormData = { ...formData, "files": null };

            if (newAudioUrl != null && newAudioUrl.length > 0) {
                for (let key of noFileFormData.fields!) {
                    if (key.key == "audio_file_url") {
                        key.value = newAudioUrl;
                        break;
                    }
                }
            }

            const requestBody = JSON.stringify(noFileFormData);
            fetch("/Data/SubmitWord", {
                method: "POST",
                body: requestBody,
                headers: {
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
            })
                .then(response => response.ok ? response.json() as Promise<SubmitWordResponseBody> : { success: false } as SubmitWordResponseBody)
                .then(data => {
                    dispatch({ type: 'RECEIVE_RESPONSE_WORD', success: !!data.success });
                    dispatch(requestWords() as any);
                    if (!data.success) {
                        console.log("submitWord failure", data);
                        return;
                    }
                    // toast("Word updated successfully!");
                });
        }

        // first, we need to check if there is a file that needs to be uploaded
        // if so, upload the file, and wait for the response as the url
        // then upload the form data with the new url

        let fileKeys = Object.keys(formData.files!);
        if (fileKeys.length > 0) {
            let fileBody = new FormData();
            fileBody.append("file", formData.files![fileKeys[0]]);

            //do a file upload
            fetch("/Data/SubmitAudioFile", {
                method: "POST",
                body: fileBody,
            })
                .then(response => response.ok ? response.json() as Promise<string> : "" as string)
                .then(data => {
                    console.log(data);
                    submitJsonInfo(data);
                });
        } else {
            submitJsonInfo();
        }
    }
};