import { Action, Reducer } from 'redux';
import {
    ApiRequestCompletedAction,
    ApiRequestStartedAction,
    ReceiveWordListAction,
    RequestWordListAction,
    requestWords,
    requestStatus,
    submitWord,
    SubmitWordAction,
    ReceiveWordAction,
    ConsumeWordResponseValueAction,
    deleteWord,
    DeleteWordAction,
    createWord,
    CreateWordSuccessAction,
} from "./actions";
import { RequestAuthCheckAction, ReceiveAuthCheckAction, AuthFailedAction, submitLogin, LogoutAction, submitLogout } from './auth';

export interface DataStoreState {
    apiRequestPending: boolean;
    apiReceived: boolean; //initially FALSE, turns TRUE after an api request has been received
    isAuthorized: boolean; //user is logged in
    wordRequestSucceeded: null | boolean;
    createdWordIndex: number;
    english_words: EnglishWords[] | null;
    chickasaw_words: ChickasawWords[] | null;
}

export interface EnglishWords {
    id: number,
    word: string,
    part_of_speech: string,
    wotd_date: string,
    has_audio: string,
    audio_file_url: string,
    notes: string,
    fKs: string,
    duplicates: number[];//EnglishWords[];
    Translated: ChickasawWords[];
    word_plain: string;
    hide: string;
}

export interface ChickasawWords {
    id: number,
    word: string,
    has_audio: string,
    audio_file_url: string,
    cn_word_pronunciation: string,
    part_of_speech: string,
    wotd_date: string,
    fKs: string,
    duplicates: number[];
    Translated: EnglishWords[];
    word_plain: string;
    hide?: number | string;
}


export type KnownAction = RequestWordListAction
    | ReceiveWordListAction
    | DeleteWordAction
    | SubmitWordAction
    | CreateWordSuccessAction
    | ConsumeWordResponseValueAction
    | ReceiveWordAction
    | RequestAuthCheckAction
    | ReceiveAuthCheckAction
    | ApiRequestStartedAction
    | ApiRequestCompletedAction
    | AuthFailedAction
    | LogoutAction
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// type WordFormData = {
//     [key: string]: string;
// };

export interface WordFormData<T = keyof ChickasawWords | keyof EnglishWords> {
    id: number;
    isChickasaw: boolean;
    fields?: {
        key: T;
        value: any;
    }[];
    files?: Record<string, File>;
}

export const actionCreators = {
    requestWords,
    requestStatus,
    createWord,
    deleteWord,
    submitWord,
    submitLogin,
    submitLogout,
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: DataStoreState = {
    createdWordIndex: -1,
    wordRequestSucceeded: null,
    apiRequestPending: false,
    apiReceived: false,
    isAuthorized: true, //false,
    english_words: null,
    chickasaw_words: null,
};

export const reducer: Reducer<DataStoreState, KnownAction> = (state: DataStoreState | undefined, incomingAction: Action): DataStoreState => {
    if (state === undefined) {
        return defaultState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "AUTH_INVALID":
            return {
                ...state,
                isAuthorized: false,
            };
        case "API_REQUEST_BEGIN":
            return {
                ...state,
                apiRequestPending: true,
            };
        case "API_REQUEST_END":
            return {
                ...state,
                apiRequestPending: false,
            };
        case "RECEIVE_RESPONSE_WORD":
            return {
                ...state,
                wordRequestSucceeded: !!action.success,
            };
        case "CONSUME_RESPONSE_WORD":
            return {
                ...state,
                wordRequestSucceeded: null,
            };
        case "DELETE_WORD":
            return {
                ...state,
                wordRequestSucceeded: null,
            };
        case "SUBMIT_WORD":
            return {
                ...state,
                wordRequestSucceeded: null,
            };
        case "WORD_CREATE_SUCCESS":
            return {
                ...state,
                createdWordIndex: action.id,
            };
        case "REQUEST_DATA_WORDS":
            return {
                ...state,
                chickasaw_words: [],
                english_words: [],
                apiRequestPending: true,
            };
        case "RECEIVE_AUTH_CHECK":
            return {
                ...state,
                apiReceived: true,
                isAuthorized: action.isAuth,
                apiRequestPending: false,
                //null out words data if isAuth fails
                ...(!action.isAuth ? {
                    chickasaw_words: null,
                    english_words: null,
                } : {}),
            };
        case "RECEIVE_DATA_WORDS":
            return {
                ...state,
                apiReceived: true,
                apiRequestPending: false,
                chickasaw_words: action.chickasaw_words,
                english_words: action.english_words,
                ...(action.chickasaw_words || action.english_words ? {
                    isAuthorized: true
                } : {}),
            };
    }

    return state;
};
