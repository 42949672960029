import React, { useCallback } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { selectIsApiRequestPending } from '../../store/selectors';
import * as DataReducer from "./../../store/data";

export const Login: React.FC = () => {
    const dispatch = useDispatch();
    const isApiRequestPending = useSelector(selectIsApiRequestPending);
    const [userVal, setUserVal] = useState("");
    const [passVal, setPassVal] = useState("");
    const handleUserChangeCb = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setUserVal(event.target.value);
    }, []);
    const handlePassChangeCb = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPassVal(event.target.value);
    }, []);
    const handleFormSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        dispatch(DataReducer.actionCreators.submitLogin(userVal, passVal));
        //dispatch({ type: 'RECEIVE_AUTH_CHECK', isAuth: true });
        //dispatch(DataReducer.actionCreators.requestWords() as any);
    }, [userVal, passVal]);
    return (
        <Form disabled={isApiRequestPending} onSubmit={handleFormSubmit}>
            <FormGroup>
                <Label for="user">Username</Label>
                <Input
                    type="text"
                    name="user"
                    id="user"
                    value={userVal}
                    onChange={handleUserChangeCb}
                />
            </FormGroup>
            <FormGroup>
                <Label for="password">Password</Label>
                <Input
                    type="password"
                    name="password"
                    id="password"
                    value={passVal}
                    onChange={handlePassChangeCb}
                />
            </FormGroup>
            <FormGroup>
                <Input type="submit" />
            </FormGroup>
        </Form>
    );
}
