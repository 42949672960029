import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import { EditorFieldBaseProps } from ".";
import { GetColumnFriendlyName } from "../../../helpers";

interface NumberDisplayProps {
    value: number;
    style?: React.CSSProperties;
}

export const NUMBER_TYPE = "number";

export const NumberDisplay: React.FC<NumberDisplayProps> = ({ value, style }) => {
    return (
        <span
            className={classNames("td", NUMBER_TYPE)}
            style={style}
        >{value}</span>
    );
};

interface NumberEditorProps extends EditorFieldBaseProps {
    value: string | null;
    handleChange: (val: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NumberEditor: React.FC<NumberEditorProps> = ({ label, value, handleChange, isEditable }) => {
    const parsedLabel = useMemo(() => GetColumnFriendlyName(label), [label]);
    const [initialValue] = useState(value);
    return (
        <FormGroup tag={Row}>
            <Col sm={2}>
                <Label className="col-form-label" for={label}>{parsedLabel}</Label>
            </Col>
            <Col sm={10}>
                <Input
                    type="number"
                    name={label}
                    id={label}
                    placeholder={initialValue ?? ""}
                    value={value ?? ""}
                    onChange={handleChange}
                    disabled={!isEditable}
                />
            </Col>
        </FormGroup>
    );
};
