import { EnglishWords, ChickasawWords } from "../../../../store/data";
import { BOOLEAN_TYPE, BooleanDisplay, BooleanEditor } from "./boolean";
import { ID_TYPE, IDDisplay, IDEditor } from "./id";
import { DUPLICATES_TYPE, DuplicatesDisplay, DuplicatesEditor } from "./duplicates";
import { MARKDOWN_TYPE, MarkdownDisplay, MarkdownEditor } from "./markdown";
import { NUMBER_TYPE, NumberDisplay, NumberEditor } from "./number";
import { STRING_TYPE, StringDisplay, StringEditor } from "./string";
import { TRANSLATED_TYPE, TranslatedDisplay, TranslatedEditor } from "./translated";
import { UNIQUE_TYPE, UniqueDisplay, UniqueEditor } from "./unique";
import { AUDIOFILE_TYPE, AudioFileDisplay, AudioFileEditor } from "./audiofile";
import { DatepickerEditor, DATEPICKER_TYPE } from "./datepicker";
import { POS_TYPE, PartOfSpeechDisplay, PartOfSpeechEditor } from "./partOfSpeech";

export {
    BOOLEAN_TYPE,
    BooleanDisplay,
    BooleanEditor,
    DUPLICATES_TYPE,
    DuplicatesDisplay,
    DuplicatesEditor,
    ID_TYPE,
    IDDisplay,
    IDEditor,
    MARKDOWN_TYPE,
    MarkdownDisplay,
    MarkdownEditor,
    NUMBER_TYPE,
    NumberDisplay,
    NumberEditor,
    STRING_TYPE,
    StringDisplay,
    StringEditor,
    TRANSLATED_TYPE,
    TranslatedDisplay,
    TranslatedEditor,
    UNIQUE_TYPE,
    UniqueDisplay,
    UniqueEditor,
    AUDIOFILE_TYPE,
    AudioFileDisplay,
    AudioFileEditor,
    DATEPICKER_TYPE,
    DatepickerEditor,
    POS_TYPE,
    PartOfSpeechEditor,
    PartOfSpeechDisplay,
};

export interface EditorFieldBaseProps {
    label: string;
    isModified: boolean;
    isEditable: boolean;
}

export interface ColumnEditorTypeMap<T extends string = string> {
    label: T;
    type: COLUMN_TYPES;
    display_in_list: boolean;
    display_in_editor: boolean;
    display_in_merge: boolean;
    editable: boolean;
    suggestions?: string[];
    listStyles?: React.CSSProperties;
}

export const ENGLISH_COLS: ColumnEditorTypeMap<keyof EnglishWords>[] = [
    {
        label: "id",
        type: "id",
        display_in_list: false,
        display_in_editor: false,
        display_in_merge: false,
        editable: false,
    },
    {
        label: "word",
        type: "string",
        display_in_list: true,
        display_in_editor: true,
        display_in_merge: true,
        editable: true,
        listStyles: {
            flexBasis: "30%",
        },
    },
    {
        label: "notes",
        type: "string",
        display_in_list: true,
        display_in_editor: true,
        display_in_merge: true,
        editable: true,
        listStyles: {
            flexBasis: "25%",
        },
    },
    {
        label: "part_of_speech",
        type: "partofspeech",
        display_in_list: true,
        display_in_editor: true,
        display_in_merge: true,
        editable: true,
        listStyles: {
            flexBasis: "15%",
        },
    },
    {
        label: "has_audio",
        type: "audiofile",
        display_in_list: true,
        display_in_editor: false,
        display_in_merge: false,
        editable: false,
        listStyles: {
            flexBasis: "10%",
        },
    },
    {
        label: "audio_file_url",
        type: "audiofile",
        display_in_list: false,
        display_in_editor: true,
        display_in_merge: true,
        editable: true,
    },
    {
        label: "wotd_date",
        type: "datepicker",
        display_in_list: false,
        display_in_editor: true,
        display_in_merge: true,
        editable: false,
    },
    {
        label: "Translated",
        type: "translated",
        display_in_list: true,
        display_in_editor: true,
        display_in_merge: false,
        editable: true,
        listStyles: {
            flexBasis: "10%",
        },
    },
    {
        label: "fKs",
        type: "string",
        display_in_list: false,
        display_in_editor: false,
        display_in_merge: false,
        editable: true,
    },
    {
        label: "duplicates",
        type: "duplicates",
        display_in_list: true,
        display_in_editor: true,
        display_in_merge: false,
        editable: true,
        listStyles: {
            flexBasis: "10%",
        },
    },
    {
        label: "hide",
        type: "boolean",
        display_in_list: false,
        display_in_editor: true,
        display_in_merge: false,
        editable: true,
    },
];

export const CHICKASAW_COLS: ColumnEditorTypeMap<keyof ChickasawWords>[] = [
    {
        label: "id",
        type: "id",
        display_in_list: false,
        display_in_editor: false,
        display_in_merge: false,
        editable: false,
    },
    {
        label: "word",
        type: "markdown",
        display_in_list: true,
        display_in_editor: true,
        display_in_merge: true,
        editable: true,
        listStyles: {
            flexBasis: "30%",
        },
    },
    {
        label: "cn_word_pronunciation",
        type: "string",
        display_in_list: true,
        display_in_editor: true,
        display_in_merge: true,
        editable: true,
        listStyles: {
            flexBasis: "40%",
        },
    },
    {
        label: "part_of_speech",
        type: "partofspeech",
        display_in_list: false,
        display_in_editor: true,
        display_in_merge: true,
        editable: true,
    },
    {
        label: "audio_file_url",
        type: "audiofile",
        display_in_list: false,
        display_in_editor: true,
        display_in_merge: true,
        editable: true,
    },
    {
        label: "has_audio",
        type: "audiofile",
        display_in_list: true,
        display_in_editor: false,
        display_in_merge: false,
        editable: false,
        listStyles: {
            flexBasis: "10%",
        },
    },
    {
        label: "wotd_date",
        type: "datepicker",
        display_in_list: false,
        display_in_editor: true,
        display_in_merge: true,
        editable: false,
    },
    {
        label: "Translated",
        type: "translated",
        display_in_list: true,
        display_in_editor: true,
        display_in_merge: false,
        editable: true,
        listStyles: {
            flexBasis: "10%",
        },
    },
    {
        label: "fKs",
        type: "string",
        display_in_list: false,
        display_in_editor: false,
        display_in_merge: false,
        editable: true,
    },
    {
        label: "duplicates",
        type: "duplicates",
        display_in_list: true,
        display_in_editor: true,
        display_in_merge: false,
        editable: true,
        listStyles: {
            flexBasis: "10%",
        },
    },
    {
        label: "hide",
        type: "boolean",
        display_in_list: false,
        display_in_editor: true,
        display_in_merge: false,
        editable: true,
    },
];

export type COLUMN_TYPES =
    typeof BOOLEAN_TYPE
    | typeof ID_TYPE
    | typeof DUPLICATES_TYPE
    | typeof MARKDOWN_TYPE
    | typeof NUMBER_TYPE
    | typeof STRING_TYPE
    | typeof TRANSLATED_TYPE
    | typeof UNIQUE_TYPE
    | typeof AUDIOFILE_TYPE
    | typeof DATEPICKER_TYPE
    | typeof POS_TYPE
    ;
