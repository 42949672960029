import { KnownAction } from ".";
import { AppThunkAction } from "..";
import { useAuth } from "oidc-react";
import { requestWords, SubmitWordResponseBody } from "./actions";

export interface RequestAuthCheckAction {
    type: 'REQUEST_AUTH_CHECK';
}

export interface ReceiveAuthCheckAction {
    type: 'RECEIVE_AUTH_CHECK';
    isAuth: boolean;
}

export interface AuthFailedAction {
    type: 'AUTH_INVALID';
}

export interface LogoutAction {
    type: 'LOGOUT';
}

export interface SubmitLoginResponseBody {
    success: boolean;
}

export const submitLogin = (user: string, pass: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // const appState = getState();
    dispatch({ type: "API_REQUEST_BEGIN" });
    const requestBody = JSON.stringify({ user, pass });
    fetch("/Data/Login", {
        method: "POST",
        body: requestBody,
        headers: {
            'Accept': 'application/json, text/plain',
            'Content-Type': 'application/json'
        },
    })
        .then(response => response.ok ? response.json() as Promise<boolean> : false)
        .then(data => {
            dispatch({ type: "API_REQUEST_END" });
            dispatch({ type: "RECEIVE_AUTH_CHECK", isAuth: data });
            // dispatch({ type: "RECEIVE_AUTH_CHECK", isAuth: true });

            if (!data) {
                console.log("submitLogin failure", data);
            }

            dispatch(requestWords() as any);
        });
};

export const submitLogout = (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: "RECEIVE_AUTH_CHECK", isAuth: false });
    fetch("/Data/Logout")
        .then(response => console.log(response));
};
