import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FormGroup, Label, Input, Col, Row } from "reactstrap";
import { EditorFieldBaseProps } from ".";
import { ChickasawWords, EnglishWords } from "../../../../store/data";
import { GetColumnFriendlyName } from "../../../helpers";

export const DUPLICATES_TYPE = "duplicates";

interface DuplicatesDisplayProps {
    value: (ChickasawWords | EnglishWords)[];
    style?: React.CSSProperties;
}

export const DuplicatesDisplay: React.FC<DuplicatesDisplayProps> = ({ value, style }) => {
    return (
        <span
            className={classNames("td", DUPLICATES_TYPE)}
            style={style}
        >{value.length}</span>
    );
};

interface DuplicatesEditorProps extends EditorFieldBaseProps {
    value: number[] | null;
    handleChange: (val: React.ChangeEvent<HTMLInputElement>) => void;
    record: ChickasawWords | EnglishWords;
}

export const DuplicatesEditor: React.FC<DuplicatesEditorProps> = ({ label, value, handleChange, isEditable, isModified, record }) => {
    //Now part of ID

    // const parsedLabel = useMemo(() => GetColumnFriendlyName(label), [label]);
    // const location = useLocation();
    // const urlPathParts = location.pathname.split("/");
    // urlPathParts.pop();
    // const rootUrl = urlPathParts.join("/");

    // if (value && value.length) {
    //     return (
    //         <div className="duplicates_editor">
    //             {value.map((val, idx) => (
    //                 <NavLink key={idx} to={rootUrl + "/" + val}>{val}</NavLink>
    //             ))}
    //         </div>
    //         // <FormGroup className="duplicated_words" tag={Row}>
    //         //     <Col sm={2}>
    //         //         <Label className="col-form-label" for={label}>Other '{parsedLabel}'</Label>
    //         //     </Col>
    //         //     <Col sm={10} className="duplicate_links">
    //         //         {value.map((val, idx) => (
    //         //             <NavLink key={idx} to={rootUrl + "/" + val}>{val}</NavLink>
    //         //         ))}
    //         //     </Col>
    //         // </FormGroup>
    //     );
    // }

    return null;
};
