import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ChickasawWords, EnglishWords } from "../../../store/data";
import {
    ColumnEditorTypeMap,
    BooleanDisplay,
    BOOLEAN_TYPE,
    MarkdownDisplay,
    MARKDOWN_TYPE,
    NumberDisplay,
    NUMBER_TYPE,
    StringDisplay,
    STRING_TYPE,
    TranslatedDisplay,
    TRANSLATED_TYPE,
    UniqueDisplay,
    UNIQUE_TYPE,
    ID_TYPE,
    IDDisplay,
    DUPLICATES_TYPE,
    DuplicatesDisplay,
    AUDIOFILE_TYPE,
    AudioFileDisplay,
    POS_TYPE,
    PartOfSpeechDisplay,
} from "./fields";

interface EditorListRowProps {
    index: number;
    style: any;
    data: {
        sortedWordData: (ChickasawWords | EnglishWords)[];
        fieldData: ColumnEditorTypeMap[];
    };
}

export const WordListRow: React.FC<EditorListRowProps> = ({ index, style, data }) => {
    const activeElement = data.sortedWordData[index];
    const location = useLocation();
    
    if (activeElement == null) {
        return null;
    }
    return (
        <Link
            title={activeElement.word}
            className="listRow"
            // onClick={() => clickCallback(activeElement.id)}
            to={location.pathname + "/" + activeElement.id}
            style={style}
        >
            {data.fieldData.map((val, idx) => {
                if (!val.display_in_list) {
                    return null;
                }
                const value = (activeElement as any)[val.label!];
                switch (val.type) {
                    case BOOLEAN_TYPE:
                        return <BooleanDisplay
                            value={value}
                            key={idx}
                            style={val.listStyles}
                        />;
                    case AUDIOFILE_TYPE:
                        return <AudioFileDisplay
                            value={value}
                            record={activeElement}
                            key={idx}
                            style={val.listStyles}
                        />;
                    case TRANSLATED_TYPE:
                        return <TranslatedDisplay
                            value={value}
                            key={idx}
                            style={val.listStyles}
                        />;
                    case ID_TYPE:
                        return <IDDisplay
                            value={value}
                            key={idx}
                            style={val.listStyles}
                        />;
                    case DUPLICATES_TYPE:
                        return <DuplicatesDisplay
                            value={value}
                            key={idx}
                            style={val.listStyles}
                        />;
                    case UNIQUE_TYPE:
                        return <UniqueDisplay
                            value={value}
                            label={val.label as any}
                            key={idx}
                            style={val.listStyles}
                        />;
                    case NUMBER_TYPE:
                        return <NumberDisplay
                            value={value}
                            key={idx}
                            style={val.listStyles}
                        />;
                    case MARKDOWN_TYPE:
                        return <MarkdownDisplay
                            value={value}
                            key={idx}
                            style={val.listStyles}
                        />;
                    case POS_TYPE:
                        return <PartOfSpeechDisplay
                            value={value}
                            key={idx}
                            style={val.listStyles}
                        />;
                    case STRING_TYPE:
                    default:
                        return <StringDisplay
                            value={value}
                            key={idx}
                            style={val.listStyles}
                        />;
                }
            })}
        </Link>
    );
};
