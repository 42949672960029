import marked from "marked";
import React, { PropsWithChildren } from "react";
import { components } from "react-select";

export const SelectOption: React.FC<PropsWithChildren<any>> = (props) => {
    const parsed = marked.parseInline(props.children);
    const newChild = <div dangerouslySetInnerHTML={{ __html: parsed }}/>;
    const newProps = {
        ...props,
        children: newChild,
    };
    return (
        <components.Option {...newProps} />
    );
};
