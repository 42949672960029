import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import RichTextEditor from 'react-rte';
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { toast } from "react-toastify";
import { actionCreators, ChickasawWords, EnglishWords, WordFormData } from "../../store/data";
import { selectChickasawWords, selectDuplicateRecords, selectEnglishWords, selectIsApiRequestPending, selectWhichTab, selectWordRequestSucceeded } from "../../store/selectors";
import { BooleanEditor, ColumnEditorTypeMap, MarkdownEditor, NumberEditor, StringEditor, UniqueEditor, AudioFileEditor, TranslatedEditor, IDEditor, DuplicatesEditor, DatepickerEditor, PartOfSpeechEditor } from "./list/fields";
import marked from "marked";
import { NavLink } from "react-router-dom";
import { MergeEditor } from "./list/fields/merge";
import { RecordHasDuplicates } from "../helpers";

interface EditorPanelProps {
    fieldData: ColumnEditorTypeMap[];
    record: ChickasawWords | EnglishWords;
}

export const EditorPanel: React.FC<EditorPanelProps> = ({ fieldData, record }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [recordModified, setRecordModified] = useState(false);
    const [modifiedFields, setModifiedFields] = useState<Record<keyof typeof record, boolean>>({} as any);
    const isApiRequestPending = useSelector(selectIsApiRequestPending);
    const wordRequestSucceeded = useSelector(selectWordRequestSucceeded);
    const currentTab = selectWhichTab(location);

    const recordDuplicates = useSelector(selectDuplicateRecords(location, record));

    // populate an object with the record's initial values
    const [columnValues, setColumnValues] = useState<any[]>(new Array(fieldData.length));

    const [files, setFiles] = useState<any[]>(new Array(fieldData.length));

    const initialColValues = useMemo(() => {
        if (record == null) {
            return [];
        }
        var vals = fieldData.map((val, idx) => (record as any)[val.label]);
        return vals;
    }, [fieldData, record]);

    useEffect(() => {
        if (wordRequestSucceeded == null) {
            return;
        }
        if (wordRequestSucceeded === false) {
            toast("There was a problem updating this record.\r\nPlease try again, or contact an administrator if the problem persists.", { type: "error" });
        }
        else if (wordRequestSucceeded === true) {
            toast("Word updated successfully!", { type: "success" });
            setModifiedFields({} as any);
            setRecordModified(false);
            dispatch({ type: 'CONSUME_RESPONSE_WORD' });
        }
    }, [wordRequestSucceeded]);

    const displayRecords = useMemo(() => {
        if (record == null) {
            return [];
        }

        // updating this to pull values from the editor rather than static db values
        let records: (ChickasawWords | EnglishWords)[] = [];

        // update the record based on fieldData
        var newRecord = {} as any;

        for (var idx = 0; idx < fieldData.length; idx++) {
            var thisData = fieldData[idx];
            var curVal = columnValues[idx];
            if (typeof curVal != "undefined") {
                newRecord[thisData.label] = curVal;
            } else {
                newRecord[thisData.label] = (record as any)[thisData.label];
            }
        }

        records.push(newRecord);

        if (recordDuplicates != null && recordDuplicates.length > 0) {
            records = records.concat(recordDuplicates);
        }

        records.sort((a, b) => a.word == b.word ? a.id - b.id : (
            a.word > b.word ? 1 : -1
        ));

        return records;
    }, [columnValues, record, fieldData, recordDuplicates]);

    const setInitialValues = useCallback(() => {
        if (record != null) {
            setColumnValues(initialColValues);
        }
        setModifiedFields({} as any);
        setRecordModified(false);
    }, [fieldData, record, location.hash, initialColValues]);

    useEffect(() => {
        if (isApiRequestPending == false) {
            setInitialValues();
        }
    }, [isApiRequestPending, setInitialValues]);

    const customValueChangeCb = useCallback((fieldName: string, value: any, sendToServer: boolean) => {
        setColumnValues((oldVal) => {
            const newValues = [...oldVal];
            const idx = fieldData.findIndex((m) => m.label == fieldName);
            if (idx >= 0) {
                newValues[idx] = value;
            }
            return newValues;
        });

        setRecordModified(true);

        if (sendToServer) {
            setModifiedFields((fld) => ({ ...fld, [fieldName]: true }));
        }
    }, [fieldData]);

    // handle input value change
    const handleValueChangeCb = useCallback((idx: number) => (val: { currentTarget: { value: any, checked?: boolean, name: string } /*EventTarget & HTMLInputElement*/ }) => {
        //currrentTarget.//value: string//checked:boolean //name: string
        const currentTarget = val.currentTarget;
        let newValue = currentTarget.value;
        if (newValue == "on") {
            newValue = currentTarget.checked ? 1 : 0;
        }

        setColumnValues((oldVal) => {
            const newValues = [...oldVal];
            if (idx >= 0) {
                newValues[idx] = newValue;
            }
            return newValues;
        });

        setRecordModified(true);
        setModifiedFields((fld) => ({ ...fld, [currentTarget.name]: true }));
    }, [columnValues]);

    const handleFileChangeCb = useCallback((idx: number) => (file?: File) => {
        if (typeof file == "undefined" || file == null) {
            //clear out the file
            setFiles([]);
            setColumnValues((oldVal) => {
                const newValues = [...oldVal];
                if (idx >= 0) {
                    newValues[idx] = "";
                }
                return newValues;
            });
        } else {
            setFiles((val) => {
                var newVal = [];
                for (let i = 0; i < val.length; i++) {
                    if (i == idx) {
                        newVal[i] = file;
                    } else {
                        newVal[i] = val[i];
                    }
                }
                return { ...newVal };
            });
        }
        setRecordModified(true);
        setModifiedFields((fld) => ({ ...fld, [fieldData[idx].label]: true }));
    }, [fieldData, setFiles, setColumnValues, setModifiedFields, setRecordModified]);

    const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const submitWordModel: WordFormData = {
            id: record.id,
            isChickasaw: currentTab == "Chickasaw",
            fields: [],
            files: {},
        };

        for (const field in modifiedFields) {
            if ((modifiedFields as any)[field] === true) {
                let recordIdx = fieldData.indexOf(fieldData.find((col) => col.label == field)!);

                const value = columnValues[recordIdx];
                submitWordModel.fields!.push({
                    key: field as any,
                    value,
                });

                const fileValue = files[recordIdx];
                if (fileValue != null) {
                    //add a file
                    submitWordModel.files![field] = fileValue;
                }
            }
        }

        dispatch(actionCreators.submitWord(submitWordModel));
    }, [record, modifiedFields]);

    const handleReset = useCallback((event, showNotification = true) => {
        event?.preventDefault();
        setInitialValues();
        setRecordModified(false);
        setModifiedFields({} as any);
        if (showNotification) {
            toast("Form Reset", { toastId: "reset", type: "info" });
        }
    }, [record]);

    const handlePlayAudioClick = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        if (event.currentTarget != null && event.currentTarget.nextSibling != null) {
            const thisButton = event.currentTarget;
            const audioElement = thisButton.nextSibling as HTMLAudioElement;
            if (audioElement != null && "play" in audioElement) {
                const playFn = () => {
                    thisButton.disabled = true;
                    audioElement.removeEventListener("play", playFn);
                };
                audioElement.addEventListener("play", playFn);

                const endFn = () => {
                    thisButton.disabled = false;
                    audioElement.removeEventListener("ended", endFn);
                };
                audioElement.addEventListener("ended", endFn);

                audioElement.play();
            }
        }
    }, []);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const handleDeleteRecordBtnClick = useCallback(() => {
        //show a confirmation modal
        setDeleteModalOpen(true);
    }, [setDeleteModalOpen]);

    const handleModalDeleteYesClick = useCallback(() => {
        // send a delete request to server

        const deleteWordModel: WordFormData = {
            id: record.id,
            isChickasaw: currentTab == "Chickasaw",
        };

        dispatch(actionCreators.deleteWord(deleteWordModel));
        // then refresh the data and redirect to
        // A) one of the duplicates or B) the list of words if no dupes
        setDeleteModalOpen(false);

        const baseUrl = "/words/" + currentTab + "/";
        if ("duplicates" in record && record.duplicates != null && record.duplicates.length > 0 && record.duplicates[0] != null) {
            history.push(baseUrl + record.duplicates[0]);
        } else {
            history.push(baseUrl);
        }
    }, [currentTab, record, setDeleteModalOpen]);

    const recordHasDuplicates = useMemo(() => RecordHasDuplicates(record), [record]);

    const [mergeViewOpen, setMergeViewOpen] = useState(false);
    // disable merge view when swapping between records
    useEffect(() => {
        setMergeViewOpen(false);
    }, [record]);

    const handleMergeBtnClick = useCallback(() => {
        setMergeViewOpen((val) => !val);
        handleReset(null, false);
    }, [setMergeViewOpen, handleReset]);

    const isFormDisabled = isApiRequestPending;

    if (typeof record == "undefined" || record == null) {
        return null;
    }

    return (<>
        {deleteModalOpen ? (
            <div className="delete_modal">
                <div className="modal_body">
                    <div>
                        <h1>Warning</h1>
                        <p>Are you sure you want to delete this record?</p>
                    </div>
                    <div className="buttons">
                        <Button
                            onClick={handleModalDeleteYesClick}
                            color="danger"
                        >DELETE</Button>
                        <Button
                            color="warning"
                            onClick={() => setDeleteModalOpen(false)}
                        >Cancel</Button>
                    </div>
                </div>
            </div>
        ) : null}
        {recordHasDuplicates ? (
            <IDEditor
                record={record}
            />
        ) : null}
        <div className="editor_panel">
            <Form onSubmit={handleSubmit} onReset={handleReset}>
                {isFormDisabled ? (
                    <div className="loading_notice debug_notice">Loading...</div>
                ) : null}
                <fieldset disabled={isFormDisabled}>
                    {mergeViewOpen ?
                        fieldData.map((val, idx) => {
                            if (!val.display_in_merge) {
                                return;
                            }
                            return (
                                <MergeEditor
                                    key={idx}
                                    label={val.label}
                                    value={columnValues[idx]}
                                    duplicates={recordDuplicates}
                                    handleChange={customValueChangeCb}
                                    isModified={(modifiedFields as any)[val.label] === true}
                                    isEditable={val.editable}
                                />
                            );
                        })
                        :
                        fieldData.map((val, idx) => {
                            if (!val.display_in_editor) {
                                return;
                            }
                            switch (val.type) {
                                case "number":
                                    return (
                                        <NumberEditor
                                            key={idx}
                                            label={val.label}
                                            value={columnValues[idx]}
                                            handleChange={handleValueChangeCb(idx)}
                                            isModified={(modifiedFields as any)[val.label] === true}
                                            isEditable={val.editable}
                                        />
                                    );
                                case "boolean":
                                    return (
                                        <BooleanEditor
                                            key={idx}
                                            label={val.label}
                                            value={columnValues[idx]}
                                            handleChange={handleValueChangeCb(idx)}
                                            isModified={(modifiedFields as any)[val.label] === true}
                                            isEditable={val.editable}
                                        />
                                    );
                                case "unique":
                                    return (
                                        <UniqueEditor
                                            key={idx}
                                            label={val.label as any}
                                            value={columnValues[idx]}
                                            handleChange={handleValueChangeCb(idx)}
                                            isModified={(modifiedFields as any)[val.label] === true}
                                            isEditable={val.editable}
                                            record={record}
                                        />
                                    );
                                case "markdown":
                                    return (
                                        <MarkdownEditor
                                            key={idx}
                                            label={val.label}
                                            value={columnValues[idx]}
                                            handleChange={handleValueChangeCb(idx)}
                                            isModified={(modifiedFields as any)[val.label] === true}
                                            isEditable={val.editable}
                                        />
                                    );
                                case "audiofile":
                                    return (
                                        <AudioFileEditor
                                            key={idx}
                                            label={val.label}
                                            value={columnValues[idx]}
                                            handleChange={handleFileChangeCb(idx)}
                                            isModified={(modifiedFields as any)[val.label] === true}
                                            isEditable={val.editable}
                                        />
                                    );
                                case "duplicates":
                                    return (
                                        <DuplicatesEditor
                                            key={idx}
                                            label={val.label}
                                            value={columnValues[idx]}
                                            handleChange={handleValueChangeCb(idx)}
                                            isModified={(modifiedFields as any)[val.label] === true}
                                            isEditable={val.editable}
                                            record={record}
                                        />
                                    );
                                case "partofspeech":
                                    return (
                                        <PartOfSpeechEditor
                                            key={idx}
                                            label={val.label}
                                            value={columnValues[idx]}
                                            handleChange={handleValueChangeCb(idx)}
                                            isModified={(modifiedFields as any)[val.label] === true}
                                            isEditable={val.editable}
                                        />
                                    );
                                case "string":
                                    return (
                                        <StringEditor
                                            key={idx}
                                            label={val.label}
                                            value={columnValues[idx]}
                                            handleChange={handleValueChangeCb(idx)}
                                            isModified={(modifiedFields as any)[val.label] === true}
                                            isEditable={val.editable}
                                        />
                                    );
                                // case "id":
                                //     return (
                                //         <IDEditor
                                //             key={idx}
                                //             value={columnValues[idx]}
                                //             record={record}
                                //         />
                                //     );
                                case "datepicker":
                                    return (
                                        <DatepickerEditor
                                            key={idx}
                                            label={val.label}
                                            value={columnValues[idx]}
                                            handleChange={handleValueChangeCb(idx)}
                                            isModified={(modifiedFields as any)[val.label] === true}
                                            isEditable={val.editable}
                                        />
                                    );
                                case "translated":
                                    return (
                                        <TranslatedEditor
                                            key={idx}
                                            label={val.label}
                                            value={columnValues[idx]}
                                            disabled={isFormDisabled}
                                            record={record}
                                            handleChange={customValueChangeCb}
                                            isModified={(modifiedFields as any)[val.label] === true}
                                            isEditable={val.editable}
                                        />
                                    )
                                default:
                                    return (
                                        <div>editorPanel::render: No Editor set for {val.label}</div>
                                    );
                            }
                        })}
                </fieldset>
                <div className="form-group form-buttons">
                    <Button color="success" type="submit" disabled={!recordModified}>Submit Changes</Button>
                    {recordHasDuplicates ? (
                        <Button
                            color="info"
                            type="button"
                            disabled={false}
                            onClick={handleMergeBtnClick}
                            title="Merge view"
                        >{mergeViewOpen ? "Cancel Merge" : "Merge Records"}</Button>
                    ) : null}
                    <Button
                        color="warning"
                        type="reset"
                        disabled={!recordModified}
                        title="Resets any changes made to this record."
                    >Undo Changes</Button>
                    {mergeViewOpen ? null : (
                        <Button
                            color="danger"
                            type="button"
                            disabled={false}
                            title="Delete this record."
                            onClick={handleDeleteRecordBtnClick}
                        >Delete Record</Button>
                    )}
                </div>
            </Form>
            {displayRecords != null && displayRecords.length > 0 ? (
                <div className="word_previews">
                    {displayRecords.map((val, idx) => {
                        if (val == null || (
                            "hide" in val && val.hide != null
                            && (val.hide == 1 || val.hide.toString().toLowerCase() == "true")
                        )) {
                            return null;
                        }

                        //const inlineHtml = marked.parseInline(val.word);
                        const inlineHtml = RichTextEditor.createValueFromString(val.word, "markdown").toString("html");

                        const urlTarget = "/words/"
                            + (currentTab == "Chickasaw" ? "chickasaw" : "english")
                            + "/" + val.id;

                        if (currentTab == "Chickasaw") {
                            return (
                                <div key={idx} className="preview_position chickasaw">
                                    <NavLink
                                        to={urlTarget}
                                        className="preview_box">
                                        <div className="flex">
                                            <span className="preview_word" dangerouslySetInnerHTML={{ __html: inlineHtml }} />
                                            {val.part_of_speech != null && val.part_of_speech.length > 0 ? (
                                                <span className="preview_pos">{val.part_of_speech}</span>
                                            ) : null}
                                        </div>
                                        {"cn_word_pronunciation" in val ? (
                                            <div className="preview_pron">{val.cn_word_pronunciation}</div>
                                        ) : null}
                                        {"Translated" in val && val.Translated != null && val.Translated.length > 0 ? (
                                            <ul className="preview_trnsl">
                                                {(val.Translated as EnglishWords[]).map((trn, innerIdx) => {
                                                    if (trn == null || trn.word == null) {
                                                        return;
                                                    }
                                                    return (
                                                        <li key={innerIdx}>
                                                            <span dangerouslySetInnerHTML={{ __html: marked.parseInline(trn.word) }}></span>
                                                            {typeof trn.part_of_speech != "undefined" && trn.part_of_speech != null && trn.part_of_speech.length ? (
                                                                <span className="preview_pos">&nbsp;({trn.part_of_speech})</span>
                                                            ) : null}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        ) : null}
                                        {val.has_audio && val.audio_file_url != null && val.audio_file_url.length ? (
                                            <React.Fragment>
                                                <button onClick={handlePlayAudioClick} className="preview_play_btn">Play Audio</button>
                                                <audio hidden src={val.audio_file_url} />
                                            </React.Fragment>
                                        ) : null}
                                    </NavLink>
                                </div>
                            );
                        } else {
                            return (
                                <div key={idx} className="preview_position english">
                                    <NavLink
                                        to={urlTarget}
                                        className="preview_box">
                                        <div className="flex">
                                            <span className="preview_word" dangerouslySetInnerHTML={{ __html: inlineHtml }} />
                                            {val.part_of_speech != null && val.part_of_speech.length > 0 ? (
                                                <span className="preview_pos">{val.part_of_speech}</span>
                                            ) : null}
                                            {(val as EnglishWords).notes != null && (val as EnglishWords).notes.length > 0 ? (
                                                <span className="preview_pos">({(val as EnglishWords).notes})</span>
                                            ) : null}
                                        </div>
                                        {val.Translated != null && val.Translated.length > 0 && val.Translated[0] != null ? (
                                            <ul className="preview_trnsl">
                                                <div>
                                                    <span dangerouslySetInnerHTML={{ __html: marked.parseInline(val.Translated[0].word) }}></span>
                                                    {"cn_word_pronunciation" in val.Translated[0] ? (
                                                        <div className="preview_pron">{val.Translated[0].cn_word_pronunciation}</div>
                                                    ) : null}
                                                    {val.Translated[0].part_of_speech != null ? (
                                                        <span className="preview_pos">&nbsp;({val.Translated[0]?.part_of_speech})</span>
                                                    ) : null}
                                                </div>
                                                {/* Originally this rendered every translation, but on the front end it currently only shows one, not all
                                                {(val.Translated as ChickasawWords[]).map((trn, innerIdx) => (
                                                    <div key={innerIdx}>
                                                        <div className="preview_pron">{trn.cn_word_pronunciation}</div>
                                                        <span dangerouslySetInnerHTML={{ __html: marked.parseInline(trn.word) }}></span>
                                                        {trn.part_of_speech != null ? (
                                                            <span className="preview_pos">&nbsp;({trn?.part_of_speech})</span>
                                                        ) : null}
                                                    </div>
                                                ))} */}
                                            </ul>
                                        ) : null}
                                        {val.has_audio && val.audio_file_url != null && val.audio_file_url.length ? (
                                            <React.Fragment>
                                                <button onClick={handlePlayAudioClick} className="preview_play_btn">Play Audio</button>
                                                <audio hidden src={val.audio_file_url} />
                                            </React.Fragment>
                                        ) : null}
                                    </NavLink>
                                </div>
                            );
                        }
                    })}
                </div>
            ) : null}
        </div>
    </>);
};
